import {
  takeLatest,
  call,
  put,
  select,
} from "redux-saga/effects"
import {
  delay,
} from "redux-saga"
import { get } from "lodash"
import { isSuccess } from "services/Utils"
import CookiesStorage from "services/CookieStorage"
import {
  createOfferAPI,
  getAllOffersAPI,
  getDealBasedOnIDAPI,
  updateOfferDealStatusAPI,
  updateOfferAPI,
  updateVendorAPI,
  updateLenderAPI,
  uploadImagesForPropertyAPI,
  getContractPayloadAPI,
  createContractAPI,
} from "./api"
import {
  createOfferAction,
  getAllOffersAction,
  getCurrentDealDetailsAction,
  updateOfferDealStatusAction,
  updateOfferAction,
  updateVendorAction,
  updateLenderAction,
  toggleAllCashOfferAction,
  toggleDrawerActionAction,
  uploadImagesForPropertyAction,
  getConvertedOfferDealAction,
  createContractAction,
  setLoaderAction,
  uploadPDFToS3Action,
  uploadPDFToS3WithNewCloudFunctionAction,
  setUnselect,
} from "./actions"

function* handleOfferCreation(action) {
  try {
    const {
      payload,
      nextRoute,
      history,
      dealAgentId: id,
    } = action.data
    const res = yield call(createOfferAPI, payload, id)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      const dealId = response && response.offer_id
      yield put(createOfferAction.success(response && response.response))
      history.push(`${nextRoute}?dealId=${dealId}&agentId=${id}`)
    }
  } catch (e) {
    console.log("error", e)
    yield put(createOfferAction.failure(e))
  }
}

function* handleFetchOffers(action) {
  try {
    const {
      skip,
      limit,
      query,
    } = action.data
    const res = yield call(getAllOffersAPI, skip, limit, query)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(getAllOffersAction.success(response))
    }
  } catch (e) {
    yield put(getAllOffersAction.failure(e))
  }
}

function* handleFetchDeal(action) {
  try {
    const {
      id,
      step,
      agentId,
    } = action.data
    const res = yield call(getDealBasedOnIDAPI, step, id, agentId)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(getCurrentDealDetailsAction.success(response))
      yield put(setLoaderAction.call(false))
      if (step === "vendor_info") {
        yield put(setUnselect.call(response.selected_vendor_id))
      }
      if (step === "lender_info") {
        yield put(setUnselect.call(response.selected_lender_id))
      }
      if (response && response.is_all_cash === 1) {
        yield put(toggleAllCashOfferAction.call(true))
      }
    }
  } catch (e) {
    yield put(getCurrentDealDetailsAction.failure(e))
  }
}

function* handleUpdateOfferDealStatus(action) {
  try {
    const {
      payload,
      agentId,
      isOfferAccepted,
      history,
    } = action.data

    const res = yield call(updateOfferDealStatusAPI, payload, agentId)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(updateOfferDealStatusAction.success(response))

      if (isOfferAccepted) {
        yield put(getConvertedOfferDealAction.request({
          history,
          offerId: payload.offer_id,
          agentId,
        }))
      }
      yield put(toggleDrawerActionAction.call({
        data: null,
        bool: false,
      }))
      yield put(getAllOffersAction.request({
        skip: 0,
        limit: 300,
      }))
    }
  } catch (error) {
    yield put(updateOfferDealStatusAction.failure(error))
  }
}

function* handleOfferUpdate(action) {
  try {
    const {
      payload,
      nextRoute,
      history,
      dealId,
      lastStep,
      dealAgentId: id,
      bypassAgentId,
      routeAgentId,
    } = action.data

    const res = yield call(updateOfferAPI, payload, dealId, bypassAgentId ? "" : id)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(updateOfferAction.success(response && response.response))
      history.push(`${nextRoute}${!lastStep ? `?dealId=${dealId}&agentId=${id || routeAgentId}` : ""}`)
    }
  } catch (e) {
    yield put(updateOfferAction.failure(e))
  }
}

function* handleVendorUpdate(action) {
  try {
    const {
      vendorId,
      payload,
      nextRoute,
      history,
      dealId,
      agentId,
    } = action.data
    const res = yield call(updateVendorAPI, vendorId, payload, agentId)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(updateVendorAction.success(response && response.response))
      history.push(`${nextRoute}?dealId=${dealId}&agentId=${agentId}`)
    }
  } catch (e) {
    yield put(updateVendorAction.failure(e))
  }
}

function* handleLenderUpdate(action) {
  try {
    const {
      vendorId,
      payload,
      nextRoute,
      history,
      dealId,
      agentId,
    } = action.data
    const res = yield call(updateLenderAPI, vendorId, payload, agentId)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(updateLenderAction.success(response && response.response))
      history.push(`${nextRoute}?dealId=${dealId}&agentId=${agentId}`)
    }
  } catch (e) {
    yield put(updateLenderAction.failure(e))
  }
}

function* handleUploadImageForProperty(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(uploadImagesForPropertyAPI, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(uploadImagesForPropertyAction.success(response && response.response))
      yield put(toggleDrawerActionAction.call({
        data: null,
        bool: false,
      }))
      yield put(getAllOffersAction.request({
        skip: 0,
        limit: 300,
      }))
    }
  } catch (e) {
    yield put(uploadImagesForPropertyAction.failure(e))
  }
}

function* handlePDFUploadToS3(action) {
  try {
    const {
      file,
      objName,
      fileName,
      bucketName,
      callback,
    } = action.data

    const signedRes = yield fetch(`https://us-central1-sturdy-chimera-767.cloudfunctions.net/file-uploader?bucket=${bucketName}&filename=${file.name}`)
    const signedResJson = yield signedRes.json()
    const myHeaders = new Headers({ "Content-Type": file.type })

    const res = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    })
    if (res.status === 200 && signedResJson.fileUrlAfterUpload) {
      yield put(uploadPDFToS3Action.success(signedResJson.fileUrlAfterUpload))
      callback(signedResJson.fileUrlAfterUpload)
    }
  } catch (e) {
    yield put(uploadPDFToS3Action.failure(e))
  }
}

function* handlePDFUploadToS3WithNewCloudFunction(action) {
  try {
    const {
      file,
      objName,
      fileName,
      bucketName,
      callback,
    } = action.data

    const signedRes = yield fetch(`https://us-central1-sturdy-chimera-767.cloudfunctions.net/upload-file?bucketName=${bucketName}&fileName=${file.name}`)
    const signedResJson = yield signedRes.json()
    const myHeaders = new Headers({ "Content-Type": file.type })

    const res = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    })

    if (res.status === 200 && signedResJson.uploadedUrl) {
      yield put(uploadPDFToS3Action.success(signedResJson.uploadedUrl))

      callback(signedResJson.uploadedUrl)
    }
  } catch (e) {
    yield put(uploadPDFToS3Action.failure(e))
  }
}

function* handleOfferConversion(action) {
  try {
    const {
      offerId: id,
      history,
      agentId,
    } = action.data

    const res = yield call(getContractPayloadAPI, id)

    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      const payload = {
        ...response,
      }

      payload.current_contract_step = "basic_info"
      payload.agent_id = parseInt(agentId, 10)

      yield put(createContractAction.request({
        offerId: id,
        payload,
        history,
      }))

      yield put(getConvertedOfferDealAction.success())
    }
  } catch (error) {
    yield put(getConvertedOfferDealAction.failure(error))
  }
}

function* handleContractCreation(action) {
  try {
    const {
      offerId: id,
      payload,
      history,
    } = action.data

    const res = yield call(createContractAPI, id, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      history.push("/broker/tc/list")

      yield put(createContractAction.success(response))
    }
  } catch (error) {
    yield put(createContractAction.failure(error))
  }
}

export default function* main() {
  //offer
  yield takeLatest(createOfferAction.REQUEST, handleOfferCreation)
  yield takeLatest(getAllOffersAction.REQUEST, handleFetchOffers)
  yield takeLatest(getCurrentDealDetailsAction.REQUEST, handleFetchDeal)
  yield takeLatest(updateOfferDealStatusAction.REQUEST, handleUpdateOfferDealStatus)
  yield takeLatest(updateOfferAction.REQUEST, handleOfferUpdate)
  yield takeLatest(updateVendorAction.REQUEST, handleVendorUpdate)
  yield takeLatest(updateLenderAction.REQUEST, handleLenderUpdate)
  yield takeLatest(uploadImagesForPropertyAction.REQUEST, handleUploadImageForProperty)
  yield takeLatest(getConvertedOfferDealAction.REQUEST, handleOfferConversion)
  yield takeLatest(createContractAction.REQUEST, handleContractCreation)
  yield takeLatest(uploadPDFToS3Action.REQUEST, handlePDFUploadToS3)
  yield takeLatest(uploadPDFToS3WithNewCloudFunctionAction.REQUEST, handlePDFUploadToS3WithNewCloudFunction)
}
