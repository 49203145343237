import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  Tag,
  Button,
  Space,
  Dropdown,
  Menu,
  Modal,
  Input,
  Tabs,
  DatePicker,
  Badge,
  Select,
} from "antd";
import ConsumerApp from "container/ConsumerApp/index";
import {
  DownOutlined,
  EllipsisOutlined,
  RightOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { debounce } from "lodash";
import AppCustomisation from "dumbComponents/AppCustomisation/index";
import SearchBoards from "./SearchBoards";
import Consumers from "./Consumers";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const statusMapping = {
  request_received: "Request Received",
  dev_in_progress: "Dev In Progress",
  ready_for_devs: "Ready for Devs",
  submitted_to_app_store: "Submitted to App Store",
  app_live: "App Live",
  request_cancelled: "Request Cancelled",
};

const statusColors = {
  request_received: "blue",
  dev_in_progress: "blue",
  ready_for_devs: "purple",
  submitted_to_app_store: "orange",
  app_live: "green",
  request_cancelled: "red",
};

const styles = {
  tableContainer: {
    padding: "16px",
    backgroundColor: "#F9FAFB", // Light gray background for container
  },
  filterWrapper: {
    display: "flex",
  },
  header: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "16px",
    width: "500px",
  },
  filterButton: {
    borderRadius: "4px",
    backgroundColor: "#FFFFFF", // White background for filter button
    color: "#000000", // Black text color
    border: "1px solid #E0E0E0", // Light border color
  },
  inputSearch: {
    width: "220px",
    marginRight: "12px",
    height: "35px !important",
  },
  modalTitle: {
    fontSize: "18px",
    fontWeight: "600",
  },
  modalButton: {
    borderRadius: "4px",
  },
  actionButton: {
    borderRadius: "4px",
    backgroundColor: "#1890ff", // Primary button color
    borderColor: "#1890ff", // Border matching primary color
    color: "#FFFFFF", // White text color
  },
  dropdownMenu: {
    borderRadius: "4px",
  },
  tag: {
    cursor: "pointer",
  },
};

const ConsumerAppTable = ({
  fetchWaitlistTable,
  fetchWaitlistTableResponse,
  selectedApp,
  saveWaitlistStatus,
  saveWaitlistStatusResponse,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [reason, setReason] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedAppStatus, setSelectedAppStatus] = useState(null);
  const [openCustomiseApp, setOpenCustomiseApp] = useState(false);

  useEffect(() => {
    fetchTableData("", [], "");
  }, [fetchWaitlistTable, pagination]);

  const fetchTableData = (search, date, status) => {
    const { current, pageSize } = pagination;
    const payload = {
      startDate: date[0] ? moment(date[0]).unix() : "",
      endDate: date[1] ? moment(date[1]).unix() : "",
      status: status,
      searchText: search,
      limit: pageSize,
      skip: (current - 1) * pageSize,
    };
    fetchWaitlistTable(payload);
  };

  useEffect(() => {
    if (fetchWaitlistTableResponse?.data) {
      setDataSource(fetchWaitlistTableResponse.data.data);
    }
  }, [fetchWaitlistTableResponse]);

  const handlePageChange = (page, pageSize) => {
    setPagination({ current: page, pageSize });
    fetchTableData(searchText, selectedDates, selectedStatus);
  };

  const showModal = (record) => {
    setSelectedRequest(record);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    const payload = {
      status: selectedAppStatus,
      reason: reason,
      id: selectedRequest.app_id,
    };
    saveWaitlistStatus({
      payload,
      callback: () => {
        fetchTableData("", [], "");
      },
    });
    setIsModalVisible(false);
    setReason("");
    setSelectedAppStatus("");
    setConfirmDisabled(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setReason("");
    setSelectedAppStatus("");
    setConfirmDisabled(true);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setReason(value);
    setConfirmDisabled(value.trim() === "");
  };

  const handleStatusSelect = (record, statusKey) => {
    setSelectedRequest(record);
    showModal(record);
    setSelectedAppStatus(statusKey);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchText(value);
      fetchTableData(value, selectedDates, selectedStatus);
    }, 500),
    [fetchTableData]
  );

  const handleSearch = (e) => {
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleFilterApply = () => {
    setIsFilterVisible(false);
    fetchTableData(searchText, selectedDates, selectedStatus);
  };

  const handleFilterReset = () => {
    setSelectedStatus("");
    setSelectedDates([]);
    setIsFilterVisible(false);
    fetchTableData("", [], "");
  };

  const statusMenu = (record) => (
    <Menu style={styles.dropdownMenu}>
      {Object.keys(statusMapping).map((statusKey) => (
        <Menu.Item
          key={statusKey}
          onClick={() => handleStatusSelect(record, statusKey)}
        >
          {statusMapping[statusKey]}
        </Menu.Item>
      ))}
    </Menu>
  );

  // const redirectToCustomise = (record) => {
  //   history.push("/broker/customise");
  //   selectedApp(record);
  // };

  const actionMenu = (record) => (
    <Menu style={styles.dropdownMenu}>
      <Menu.Item
        key="cancel"
        onClick={() => {
          showModal(record);
          setSelectedAppStatus("request_cancelled");
        }}
        style={{ color: "red" }}
      >
        Cancel request
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "agent_name",
      key: "agent_name",
    },
    {
      title: "Team Name",
      dataIndex: "team_name",
      key: "team_name",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Requested Date/Time",
      dataIndex: "created_at",
      key: "requestedDateTime",
      render: (text, record) => {
        const daysAgo = record.days_passed
          ? `${record.days_passed} days ago`
          : "-";
        const formattedDate = moment(record.created_at).format("MM/DD/YYYY");
        return (
          <>
            <div>{daysAgo}</div>
            <div style={{ color: "gray" }}>{formattedDate}</div>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => {
        const statusText = statusMapping[status] || "-";
        const color = statusColors[status] || "default";
        return (
          <Dropdown overlay={statusMenu(record)} trigger={["click"]}>
            <Tag color={color} style={styles.tag}>
              {statusText}
              <DownOutlined style={{ marginLeft: 8 }} />
            </Tag>
          </Dropdown>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <Button
            style={styles.actionButton}
            onClick={() => {
              setOpenCustomiseApp(true);
              selectedApp(record);
            }}
          >
            Customize App <RightOutlined />
          </Button>
          <Dropdown overlay={actionMenu(record)} trigger={["click"]}>
            <Button icon={<EllipsisOutlined />} />
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <>
      {!openCustomiseApp ? (
        <>
          <div style={{ padding: "30px" }}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="App Requests" key="1">
                <div style={styles.tableContainer}>
                  <div style={styles.filterWrapper}>
                    <h1 style={styles.header}>Join Waitlist Requests</h1>
                    <div
                      style={{
                        marginBottom: 16,
                        width: "100%",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Input
                        placeholder="Team or Agent name"
                        // prefix={<SearchOutlined />}
                        onChange={(e) => handleSearch(e)}
                        style={styles.inputSearch}
                      />
                      <Badge
                        dot={selectedDates?.length > 0 || selectedStatus}
                        offset={[0, 0]}
                      >
                        <Button
                          style={styles.filterButton}
                          onClick={() => setIsFilterVisible(true)}
                        >
                          Filter
                        </Button>
                      </Badge>
                    </div>
                  </div>
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    loading={fetchWaitlistTableResponse?.isFetching}
                    pagination={{
                      current: pagination.current,
                      pageSize: pagination.pageSize,
                      onChange: handlePageChange,
                      total: fetchWaitlistTableResponse?.data?.total_count || 0,
                    }}
                  />
                </div>
              </TabPane>
              <TabPane tab="Consumer" key="2">
                <Consumers />
              </TabPane>
              <TabPane tab="Search boards" key="3">
                <SearchBoards />
              </TabPane>
            </Tabs>
          </div>
          <Modal
            visible={isModalVisible}
            onOk={handleOk}
            okText="Confirm"
            onCancel={handleCancel}
            okButtonProps={{ disabled: confirmDisabled }}
          >
            <div>
              <h3 style={styles.modalTitle}>
                Change App status to {statusMapping[selectedAppStatus]}
              </h3>
              <Input.TextArea
                placeholder="Enter reason for status change"
                value={reason}
                onChange={handleInputChange}
              />
            </div>
          </Modal>

          {/* Filter Modal */}
          <Modal
            title="Filter"
            visible={isFilterVisible}
            onCancel={() => setIsFilterVisible(false)}
            footer={null}
          >
            <div>
              <div style={{ marginBottom: 16 }}>
                <span style={{ marginRight: 8 }}>Status:</span>
                <Dropdown
                  overlay={
                    <Menu onClick={({ key }) => setSelectedStatus(key)}>
                      {Object.keys(statusMapping).map((statusKey) => (
                        <Menu.Item key={statusKey}>
                          {statusMapping[statusKey]}
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <Button>
                    {statusMapping[selectedStatus] || "Select Status"}{" "}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
              <div>
                <span style={{ marginRight: 8, marginBottom: 10 }}>
                  Date Range:
                </span>
                <RangePicker
                  value={selectedDates}
                  onChange={(dates) => setSelectedDates(dates)}
                />
              </div>
              <div style={{ marginTop: 16, textAlign: "right" }}>
                <Button style={{ marginRight: 8 }} onClick={handleFilterReset}>
                  Reset
                </Button>
                <Button type="primary" onClick={handleFilterApply}>
                  Apply
                </Button>
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <AppCustomisation
          setOpenCustomiseApp={setOpenCustomiseApp}
          statusMapping={statusMapping}
        />
      )}
    </>
  );
};

export default ConsumerApp(ConsumerAppTable);
