import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils";

import {
  fetchAppCustomisationsAction,
  fetchWaitlistAction, saveWaitlistStatusAction, selectedAppAction, updateAppCustomisationsAction, uploadAppAssetsAction, fetchConsumerAction,
  fetchSearchBoardAction,
  fetchPropertiesAction,
  fetchFamilyMembersAction,
  fetchConsumerTeamsAction
} from "./actions";

const initialState = {
  selectedAppObject: {},
  ...createDeltaReducer("fetchWaitlistTableResponse"),
  ...createDeltaReducer("fetchConsumerTableResponse"),
  ...createDeltaReducer("fetchSearchBoardTableResponse"),
  ...createDeltaReducer("fetchPropertiesResponse"),
  ...createDeltaReducer("fetchFamilyMembersResponse"),
  ...createDeltaReducer("saveWaitlistStatusResponse"),
  ...createDeltaReducer("uploadAppAssetsResponse"),
  ...createDeltaReducer("updateAppCustomisationResponse"),
  ...createDeltaReducer("fetchAppCustomisationResponse"),
  ...createDeltaReducer("fetchConsumerTeamsResponse")
  
};

const consumerAppReducer = (state = initialState, action) => {

  switch (action.type) {
    case selectedAppAction.type: {
    return {
        ...state,
        selectedAppObject: action.data,
      };
    }
    case fetchWaitlistAction.REQUEST:
    case fetchWaitlistAction.SUCCESS:
    case fetchWaitlistAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          fetchWaitlistAction,
          "fetchWaitlistTableResponse"
        ),
      };
    }

    case fetchConsumerAction.REQUEST:
    case fetchConsumerAction.SUCCESS:
    case fetchConsumerAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          fetchConsumerAction,
          "fetchConsumerTableResponse"
        ),
      };
    }

    case fetchConsumerTeamsAction.REQUEST:
    case fetchConsumerTeamsAction.SUCCESS:
    case fetchConsumerTeamsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          fetchConsumerTeamsAction,
          "fetchConsumerTeamsResponse"
        ),
      };
    }

    case fetchSearchBoardAction.REQUEST:
    case fetchSearchBoardAction.SUCCESS:
    case fetchSearchBoardAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          fetchSearchBoardAction,
          "fetchSearchBoardTableResponse"
        ),
      };
    }

    case fetchPropertiesAction.REQUEST:
    case fetchPropertiesAction.SUCCESS:
    case fetchPropertiesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          fetchPropertiesAction,
          "fetchPropertiesResponse"
        ),
      };
    }

    case fetchFamilyMembersAction.REQUEST:
    case fetchFamilyMembersAction.SUCCESS:
    case fetchFamilyMembersAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          fetchFamilyMembersAction,
          "fetchFamilyMembersResponse"
        ),
      };
    }

    case saveWaitlistStatusAction.REQUEST:
    case saveWaitlistStatusAction.SUCCESS:
    case saveWaitlistStatusAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          saveWaitlistStatusAction,
          "saveWaitlistStatusResponse"
        ),
      };
    }
      
    case uploadAppAssetsAction.REQUEST:
    case uploadAppAssetsAction.SUCCESS:
    case uploadAppAssetsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          uploadAppAssetsAction,
          "uploadAppAssetsResponse"
        ),
      };
    }
      
    case updateAppCustomisationsAction.REQUEST:
    case updateAppCustomisationsAction.SUCCESS:
    case updateAppCustomisationsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          updateAppCustomisationsAction,
          "updateAppCustomisationResponse"
        ),
      };
    }
    case fetchAppCustomisationsAction.REQUEST:
    case fetchAppCustomisationsAction.SUCCESS:
    case fetchAppCustomisationsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          fetchAppCustomisationsAction,
          "fetchAppCustomisationResponse"
        ),
      };
    }
    default:
      return state;
  }
  
};

export default consumerAppReducer;
