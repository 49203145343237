import {
  createSignalAction,
  createSimpleCreator,
} from "shared/reduxUtils"

const BASE = "RAR_TECH_ASSETS"

// offers
export const getCurrentDealDetailsAction = createSignalAction(BASE, "GET_DEAL_DETAILS")
export const getAllOffersAction = createSignalAction(BASE, "GET_ALL_OFFERS")
export const createOfferAction = createSignalAction(BASE, "CREATE_OFFER")
export const updateOfferAction = createSignalAction(BASE, "UPDATE_OFFER")
export const updateOfferDealStatusAction = createSignalAction(BASE, "UPDATE_OFFER_DEAL_STATUS")
export const resetLastDealAction = createSimpleCreator(BASE, "RESET_DEAL_FETCHED")
export const setCurrentVendorEditDetailsAction = createSimpleCreator(BASE, "SET_CURRENT_VENDOR_EDIT")
export const setCurrentLenderEditDetailsAction = createSimpleCreator(BASE, "SET_CURRENT_LENDER_EDIT")
export const updateVendorAction = createSignalAction(BASE, "UPDATE_VENDOR")
export const updateLenderAction = createSignalAction(BASE, "UPDATE_LENDER")
export const toggleAllCashOfferAction = createSimpleCreator(BASE, "TOGGLE_ALL_CASH_OFFER")
export const updateStatusNoteForChangeAction = createSimpleCreator(BASE, "UPDATE_NOTE_FOR_STATUS_CHANGE")
export const setOfferFilterQueryAction = createSimpleCreator(BASE, "SET_OFFER_FILTER_QUERIES")
export const toggleDrawerActionAction = createSimpleCreator(BASE, "TOGGLE_DRAWER")
export const uploadImagesForPropertyAction = createSignalAction(BASE, "UPLOAD_IMAGE_FOR_PROPERTY")
export const getConvertedOfferDealAction = createSignalAction(BASE, "OFFER_CONVERTED_CONTRACT_DEAL")
export const createContractAction = createSignalAction(BASE, "CONVERT_OFFER")
export const uploadPDFToS3Action = createSignalAction(BASE, "UPLOAD_PDF_FOR_MORTGAGE")
export const uploadPDFToS3WithNewCloudFunctionAction = createSignalAction(BASE, "UPLOAD_PDF_TO_S3_WITH_NEW_CLOUD_FUNCTION")
export const setOfferInfoDetailsAction = createSimpleCreator(BASE, "SET_OFFER_INFO")
export const setLoaderAction = createSimpleCreator(BASE, "SET_LOADER")
export const setUnselect = createSimpleCreator(BASE, "SET_UNSELECT")
