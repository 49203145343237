import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as Actions from "./actions"

const initState = {
  showPopupDetails: {
    showMortgagePopup: false,
  },
  toggleScreenPopupStep: null,
  isShow: null,
  fileUploading: null,
  businessCard: {},
  yardSign: {},
  preferrerdTitle: "",
  bothDone: null,
  // dealToView: 2157,
  dealToView: null,
  loaderOnAgentInfo: true,
  // loaderOnAgentInfo: false,
  escrowIdToEdit: null,
  agentGoogleContacts: [],
  showViewListingHistory: false,
  showComfirmToGoToContract: false,
  showConfirmToExecute: false,
  toggleLoader: false,
  currentVendorToEdit: {
    id: null,
    data: null,
  },
  isAllCashOffer: false,
  offerStatusChangeNote: "",
  isEditDrawerOpen: false,
  currentDealToEdit: null,
  offerInfoDetails: {},
  unselect: null,
  ...createDeltaReducer("createOfferActionResponse"),
  ...createDeltaReducer("getAllOffersActionResponse"),
  ...createDeltaReducer("getCurrentDealDetailsResponse"),
  ...createDeltaReducer("updateOfferDealStatusResponse"),
  ...createDeltaReducer("updateOfferActionResponse"),
  ...createDeltaReducer("updateVendorActionResponse"),
  ...createDeltaReducer("updateLenderActionResponse"),
  ...createDeltaReducer("uploadImagesForPropertyActionResponse"),
  ...createDeltaReducer("getConvertedOfferDealActionResponse"),
  ...createDeltaReducer("createContractActionResponse"),
  ...createDeltaReducer("uploadPDFToS3Response"),
}

export default function rarTech(state = initState, action) {
  switch (action.type) {
    case Actions.createOfferAction.REQUEST:
    case Actions.createOfferAction.FAILURE:
    case Actions.createOfferAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.createOfferAction,
          "createOfferActionResponse"
        ),
      }
    }

    case Actions.getAllOffersAction.REQUEST:
    case Actions.getAllOffersAction.FAILURE:
    case Actions.getAllOffersAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.getAllOffersAction,
          "getAllOffersActionResponse"
        ),
      }
    }

    case Actions.getCurrentDealDetailsAction.REQUEST:
    case Actions.getCurrentDealDetailsAction.FAILURE:
    case Actions.getCurrentDealDetailsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.getCurrentDealDetailsAction,
          "getCurrentDealDetailsResponse"
        ),
      }
    }

    case Actions.updateOfferDealStatusAction.REQUEST:
    case Actions.updateOfferDealStatusAction.FAILURE:
    case Actions.updateOfferDealStatusAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.updateOfferDealStatusAction,
          "updateOfferDealStatusResponse"
        ),
      }
    }

    case Actions.updateOfferAction.REQUEST:
    case Actions.updateOfferAction.FAILURE:
    case Actions.updateOfferAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.updateOfferAction,
          "updateOfferActionResponse"
        ),
      }
    }

    case Actions.updateVendorAction.REQUEST:
    case Actions.updateVendorAction.FAILURE:
    case Actions.updateVendorAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.updateVendorAction,
          "updateVendorActionResponse",
        ),
      }
    }

    case Actions.updateLenderAction.REQUEST:
    case Actions.updateLenderAction.FAILURE:
    case Actions.updateLenderAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.updateLenderAction,
          "updateLenderActionResponse",
        ),
      }
    }

    case Actions.uploadImagesForPropertyAction.REQUEST:
    case Actions.uploadImagesForPropertyAction.FAILURE:
    case Actions.uploadImagesForPropertyAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.uploadImagesForPropertyAction,
          "uploadImagesForPropertyActionResponse",
        ),
      }
    }

    case Actions.uploadPDFToS3Action.REQUEST:
    case Actions.uploadPDFToS3Action.FAILURE:
    case Actions.uploadPDFToS3Action.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.uploadPDFToS3Action,
          "uploadPDFToS3Response",
        ),
      }
    }

    case Actions.uploadPDFToS3WithNewCloudFunctionAction.REQUEST:
    case Actions.uploadPDFToS3WithNewCloudFunctionAction.FAILURE:
    case Actions.uploadPDFToS3WithNewCloudFunctionAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.uploadPDFToS3WithNewCloudFunctionAction,
          "uploadPDFToS3WithNewCloudFunctionResponse",
        ),
      }
    }

    case Actions.getConvertedOfferDealAction.REQUEST:
    case Actions.getConvertedOfferDealAction.FAILURE:
    case Actions.getConvertedOfferDealAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.getConvertedOfferDealAction,
          "getConvertedOfferDealActionResponse",
        ),
      }
    }

    case Actions.createContractAction.REQUEST:
    case Actions.createContractAction.FAILURE:
    case Actions.createContractAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.createContractAction,
          "createContractActionResponse",
        ),
      }
    }

    case Actions.setCurrentVendorEditDetailsAction.type: {
      const {
        id,
        data,
      } = action.data

      return {
        ...state,
        currentVendorToEdit: {
          id,
          data,
        },
      }
    }

    case Actions.resetLastDealAction.type: {
      return {
        ...state,
        offerStatusChangeNote: "",
        getCurrentDealDetailsResponse: {
          data: null,
          isFetching: false,
          error: false,
        },
      }
    }

    case Actions.updateStatusNoteForChangeAction.type: {
      return {
        ...state,
        offerStatusChangeNote: action.data,
      }
    }

    case Actions.toggleAllCashOfferAction.type: {
      return {
        ...state,
        isAllCashOffer: action.data,
      }
    }

    case Actions.setLoaderAction.type: {
      return {
        ...state,
        toggleLoader: action.data,
      }
    }

    case Actions.setUnselect.type: {
      return {
        ...state,
        unselect: action.data,
      }
    }

    case Actions.setOfferInfoDetailsAction.type: {
      return {
        ...state,
        offerInfoDetails: action.data,
      }
    }

    case Actions.setOfferFilterQueryAction.type: {
      const {
        queries,
      } = action.data
      return {
        ...state,
        queryObject: queries && queries.length > 0 ? [...queries] : [queries],
      }
    }

    case Actions.toggleDrawerActionAction.type: {
      const {
        bool,
        data,
      } = action.data

      return {
        ...state,
        isEditDrawerOpen: bool,
        currentDealToEdit: data,
      }
    }

    default: {
      return state
    }
  }
}
