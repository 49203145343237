import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  Tag,
  Button,
  Space,
  Dropdown,
  Menu,
  Modal,
  Input,
  Tabs,
  DatePicker,
  Badge,
  Select,
} from "antd";
import ConsumerApp from "container/ConsumerApp/index";
import moment from "moment";
import { debounce } from "lodash";
import ViewMoreModal from "./ViewMoreModal";
import "./styles.css";

const styles = {
  tableContainer: {
    padding: "16px",
    backgroundColor: "#F9FAFB",
  },
  filterWrapper: {
    display: "flex",
  },
  header: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "16px",
    width: "500px",
  },
  filterButton: {
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    color: "#000000",
    border: "1px solid #E0E0E0",
  },
  inputSearch: {
    width: "220px",
    marginRight: "12px",
    height: "35px !important",
  },
  modalTitle: {
    fontSize: "18px",
    fontWeight: "600",
  },
  modalButton: {
    borderRadius: "4px",
  },
  actionButton: {
    borderRadius: "4px",
    backgroundColor: "#1890ff",
    borderColor: "#1890ff",
    color: "#FFFFFF",
  },
  dropdownMenu: {
    borderRadius: "4px",
  },
  tag: {
    cursor: "pointer",
  },
  favPill: {
    display: "flex",
    height: "20px",
    padding: "2px 6px",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    borderRadius: "24px",
    background: "var(--Color-Green-Green-50, #E7F5F1)",
    color: "var(--Color-Green-Green-500, #0C9F6E)",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    marginBottom: "5px",
  },
  rejectPill: {
    display: "flex",
    height: "20px",
    padding: "2px 6px",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    borderRadius: "24px",
    background: "var(--Color-Red-Red-50, #FEEEEE)",
    color: "var(--Color-Red-Red-500, #F05152)",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
  },
  greyPill: {
    display: "flex",
    height: "20px",
    padding: "2px 8px 2px 4px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "24px",
    border: "1px solid var(--Neutral-300, #D1D5DB)",
    background: "var(--Neutral-100, #F3F4F6)",
  },
  viewMore: {
    color: "var(--Global-Mapping-Primary, #5A5FF2)",
    cursor: "pointer",
  },
};

const SearchBoards = ({fetchSearchBoardTable, fetchSearchBoardTableResponse, fetchConsumerTeamsResponse, fetchConsumerTeams}) => {

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [dataSource, setDataSource] = useState([]);
  const [searchEmail, setSearchEmail] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [teamId, setTeamId] = useState("");
  const [allTeams, setAllTeams] = useState([]);
  const options = allTeams && allTeams.map((item) => ({
    value: item.team_id || item.agent_id,
    label: item.team_name || item.agent_name,
  }));

  const fetchSearchBoardTableData = () => {
    const { current, pageSize } = pagination;
    const payload = {
      limit: pageSize,
      skip: (current - 1) * pageSize,
      consumerUserId: "",
      email: searchEmail,
      location: searchLocation,
      teamId: teamId,
    };
    fetchSearchBoardTable(payload);
  };

  useEffect(() => {
    fetchConsumerTeams();
  }, []);

  useEffect(() => {
    fetchSearchBoardTableData();
  }, [fetchSearchBoardTable, pagination]);

  useEffect(() => {
    if (fetchSearchBoardTableResponse?.data) {
      setDataSource(fetchSearchBoardTableResponse.data.data);
    }
  }, [fetchSearchBoardTableResponse]);

  useEffect(() => {
    if (fetchConsumerTeamsResponse?.data) {
      setAllTeams(fetchConsumerTeamsResponse.data);
    }
  }, [fetchConsumerTeamsResponse]);

  const handlePageChange = (page, pageSize) => {
    setPagination({ current: page, pageSize });
    fetchSearchBoardTableData();
  };

  const debouncedClientEmailSearch = useCallback(
    debounce((value) => {
      setSearchEmail(value);
      const { current, pageSize } = pagination;
      const payload = {
        limit: pageSize,
        skip: (current - 1) * pageSize,
        consumerUserId: "",
        email: value,
        location: searchLocation,
        teamId: teamId,
      };
      fetchSearchBoardTable(payload);
    }, 500),
    [fetchSearchBoardTable, searchLocation, teamId]
  );

  const handleClientEmailSearch = (e) => {
    handlePageChange(1, 10);
    debouncedClientEmailSearch(e.target.value);
  };

  const debouncedLocationSearch = useCallback(
    debounce((value) => {
      setSearchLocation(value);
      const { current, pageSize } = pagination;
      const payload = {
        limit: pageSize,
        skip: (current - 1) * pageSize,
        consumerUserId: "",
        email: searchEmail,
        location: value,
        teamId: teamId,
      };
      fetchSearchBoardTable(payload);
    }, 500),
    [fetchSearchBoardTable, searchEmail, teamId]
  );

  const handleLocationSearch = (e) => {
    handlePageChange(1, 10);
    debouncedLocationSearch(e.target.value);
  };

  useEffect(() => {
    return () => {
      debouncedClientEmailSearch.cancel();
      debouncedLocationSearch.cancel();
    };
  }, [debouncedClientEmailSearch, debouncedLocationSearch]);

  const searchBoardColumns = [
    {
      title: "Team & Agent",
      dataIndex: "team_agent",
      key: "team_agent",
      width: 150,
      render: (status, record) => {
        return (
          <div>
            <p>{record.team_name}</p>
            <p style={{color: "grey"}}>{record.agent_name}</p>
          </div>
        );
      },
    },
    {
      title: "Client info",
      dataIndex: "client_info",
      key: "client_info",
      width: 150,
      render: (status, record) => {
        return (
          <div>
            <p>{record.name}</p>
            <p style={{color: "grey"}}>{record.email}</p>
          </div>
        );
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 250,
      render: (status, record) => {
        const locations = record.locations;
        return (
          <div>
            {locations && locations.slice(0, 2).map((data, index) => {
              const parts = [data.c_locality_name, data.city, data.state].filter(Boolean);
              return <p key={index}>{parts.join(', ')}</p>;
            })}
            {locations && locations.length > 2 && (
              <p
                onClick={() => handleViewMoreModal("searchBoard_locationDetails", locations)}
                style={styles.viewMore}
              >
                View more
              </p>
            )}
          </div>
        );
      },
    },
    {
      title: "Search",
      dataIndex: "search",
      key: "search",
      width: 250,
      render: (status, record) => {
        const beds = record.bedroom || "-";
        const baths = record.bathroom || "-";
        const max_size = record.max_size;
        const min_size = record.min_size;
        const data = [
          {
            beds: record.bedroom,
            baths: record.bathroom,
            area: record.area,
            max_size: record.max_size,
            min_size: record.min_size,
            notes: record.notes,
          }
        ];
        return (
          <div>
            <p>Beds: {beds === -1 ? "Studio" : beds}, Baths: {baths}, Area: {min_size} - {max_size} {record.area_unit}</p>
            <p style={{ color: "gray" }}>
              Additional notes: {record.notes ? record.notes.slice(0, 20) : "No notes available"}
              {record.notes?.length > 20 && "..."}
            </p>
            {record.notes?.length > 20 && (
              <p
                onClick={() => handleViewMoreModal("searchBoard_searchDetails", data)}
                style={styles.viewMore}
              >
                View more
              </p>
            )}
          </div>
        );
      },
    },
    {
      title: "Property Stats",
      dataIndex: "team_agent",
      key: "team_agent",
      width: 150,
      render: (status, record) => {
        const favorite = record.likes_count;
        const rejected = record.dislikes_count;
        return (
          <div>
            <p style={styles.favPill}>{favorite} favorite</p>
            <p style={styles.rejectPill}>{rejected} rejected</p>
          </div>
        );
      },
    },
    {
      title: "Last recommendation",
      dataIndex: "last_recommendation",
      key: "last_recommendation",
      width: 150,
      render: (status, record) => {
        const lastRecommendationCount = record.last_recommendation || 0;
        return (
          <div>
            <p style={styles.greyPill}>{lastRecommendationCount} properties</p>
          </div>
        );
      },
    },
    {
      title: "Last recommended date",
      dataIndex: "last_recommendation_date",
      key: "last_recommendation_date",
      width: 150,
      render: (text, record) => {
        const formattedDate = moment(record.last_recommendation_date).format("MM/DD/YYYY");
        return (
          <>
            {record.last_recommendation_date ? (<div>{formattedDate}</div>) : (<div>-</div>)}
          </>
        );
      },
    },
    {
      title: "No. of  new properties recommended",
      dataIndex: "new_recommended_prop",
      key: "new_recommended_prop",
      width: 150,
      render: (status, record) => {
        const lastRecommendationCount = record.new_recommended_prop || 0;
        return (
          <div>
            <p style={styles.greyPill}>{lastRecommendationCount} properties</p>
          </div>
        );
      },
    },
    {
      title: "Added family members",
      dataIndex: "family_member_count",
      key: "family_member_count",
      width: 150,
      render: (status, record) => {
        const famMembers = record.family_member_count;
        return (
          <div>
            <p style={styles.greyPill}>{famMembers} members</p>
          </div>
        );
      },
    },
    {
      title: "No. of messages sent",
      dataIndex: "message_count",
      key: "message_count",
      width: 150,
      render: (status, record) => {
        const msgsSent = record.message_count;
        return (
          <div>
            <p style={styles.greyPill}>{msgsSent} messages</p>
          </div>
        );
      },
    },
  ];

  const [viewMoreLocations, setViewMoreLocations] = useState(false);
  const [columnType, setColumnType] = useState();
  const [viewMoreData, setViewMoreData] = useState();

  const handleViewMoreModal = (columnType, data) => {
    setViewMoreLocations(true);
    setColumnType(columnType);
    setViewMoreData(data);
  };

  const handleCancel = () => {
    setViewMoreLocations(false);
    setColumnType(null);
  };

  const handleOptionChange = (value) => {
    if(value === "all"){
      setTeamId("");
      const { current, pageSize } = pagination;
      const payload = {
        limit: pageSize,
        skip: (current - 1) * pageSize,
        consumerUserId: "",
        email: searchEmail,
        location: searchLocation,
        teamId: "",
      };
      fetchSearchBoardTable(payload);
    }
    else {
      setTeamId(value);
      const { current, pageSize } = pagination;
      const payload = {
        limit: pageSize,
        skip: (current - 1) * pageSize,
        consumerUserId: "",
        email: searchEmail,
        location: searchLocation,
        teamId: value,
      };
      fetchSearchBoardTable(payload);
    }
  };

  return (
    <>
      {viewMoreLocations && (
        <ViewMoreModal
          isModalVisible={viewMoreLocations}
          columnType={columnType}
          handleCancel={handleCancel}
          viewMoreData={viewMoreData}
        />
      )}
      <div style={styles.tableContainer}>
        <p>Total search boards: {fetchSearchBoardTableResponse?.data?.total_count || 0}</p>
        <div style={styles.filterWrapper}>
          <h1 style={styles.header}>Search boards</h1>
          <div
            style={{
              marginBottom: 16,
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Input
              placeholder="Search by client email"
              onChange={(e) => handleClientEmailSearch(e)}
              style={styles.inputSearch}
            />
            <Input
              placeholder="Search by location"
              onChange={(e) => handleLocationSearch(e)}
              style={styles.inputSearch}
            />
            <Select
              defaultValue="all"
              style={styles.inputSearch}
              onChange={handleOptionChange}
              options={[
                { value: "all", label: "All" },
                ...options,
              ]}
            />
          </div>
        </div>
        <Table
          columns={searchBoardColumns}
          dataSource={dataSource}
          loading={fetchSearchBoardTableResponse?.isFetching}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: handlePageChange,
            total: fetchSearchBoardTableResponse?.data?.total_count || 0,
          }}
          scroll={{ x: 2000 }}
        />
      </div>
    </>
  );
};

export default ConsumerApp(SearchBoards);
