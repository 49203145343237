import React from "react"
import styled from "styled-components"
import { get, isEmpty } from "lodash"
import { unixToDate } from "services/Utils"
import TechAssets from "container/TechAssets"
import cookie from "react-cookies"
import { Switch, Route, Redirect } from "react-router-dom"
import NumberFormat from "react-number-format"
import { SALES_REPRESENTATIVE } from "dumbComponents/BrokerDashboard/GlobalPages/Resources/data"
import globalContainer from "container/BrokerDashboard/globalPagesContainer"
import teamContainer from "container/BrokerDashboard/teamContainer.js"
import agentSearchContainer from "container/BrokerDashboard/agentSearchContainer"
import InfiniteScroll from "react-infinite-scroller"
import Toggle from "dumbComponents/common/UI/Toggle"
import Loader from "dumbComponents/common/UI/Loader"
import DealList from "dumbComponents/BrokerDashboard/Components/DealItem"
import DayPickerInput from "react-day-picker/DayPickerInput"
import Select from "react-select"
import moment from "moment-timezone"

import {
  Col,
  Collapse,
  Row,
  Button,
  Spin,
  Select as MultipleSelect,
  Upload,
  Modal,
  message,
} from "antd"
import {
  GoogleAutoComplete,
} from "dumbComponents/common/UI/antd"
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons"
import { TRANSACTION_TYPE, RADIUS_TRANSACTION_TYPE } from "dumbComponents/BrokerDashboard/AgentDashboard/FieldData"
import root from "window-or-global"
import { uploadCSVToS3Action } from "container/TechAssets/actions"
import NoResults from "./NoResults"
import OverviewTC from "../../TcOverview/OverviewTC"
import AddDealsGlobal from "../Deals/AddGlobalDeals"
import SubmittedDeals from "./SubmittedDeals"

const { Option } = MultipleSelect

const Wrap = styled.div`
  margin: 20px auto;
`
const WrapAgent = styled.div`
  position: relative;
  width: 300px;
`

const AgentList = styled.div`
  position: absolute;
  width: 300px;
  top: 100px;
  left: 0px;
  right: 0px;
  background: #fff;
  border-radius: 10px;
  z-index: 1000;
  height: max-content;

  ${props => props.hasAgents && `
     border: 1px solid #ccc;
   `}

   ${props => props.hideDropdown && `
     display: none;
    `}
`

const AgentItem = styled.div`
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  &:hover {
    background: #eee;
    transition-duration: 0.3s;
    cursor: pointer;
  }
  > div {
    &:nth-child(1) {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      overflow: hidden;
      background: #eee;
      > img {
        width: 100%;
      }
    }
    &:nth-child(2) {
      margin-left: 20px;
      p, h6 {
        padding: 0px;
        margin: 0px;
      }
      h6 {
        font-weight: 400;
        color: #999;
        font-size: 12px;
      }
      > input {
        color: #999;
        border: 10px;
        font-size: 12px;
        pointer-events: none;
        padding: 0px;
        background: transparent;
      }
    }
  }
`

const Header = styled.div`
  margin: 20px;
  h1 {
    font-weight: 500;
  }
`
const Input = styled.input`
  width: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`

const MaxMinWrapper = styled.div`
  margin: 0px 8px 16px 8px;
  h6 {
    margin: 0px 0px 6px 10px;
    font-size: 16px;
  }
  > div {
    display: flex;
    input {
      margin: 0px 5px;
      padding: 11px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }

  div[class*="-container"] {
    width: 150px;
    z-index: 101;
  }

  &.source-container {
    div[class*="-container"] {
      width: 200px;
      z-index: 103;
    }
    div[class*="-control"] {
      width: 200px;
    }
  }

  &.client-type-container {
    div[class*="-container"] {
      width: 200px;
      z-index: 103;
    }
    div[class*="-control"] {
      width: 200px;
    }
  }

  &.agent-type-container {
    div[class*="-container"] {
      width: 200px;
      z-index: 102;
    }
    div[class*="-control"] {
      width: 200px;
    }
  }

  &.transaction-type-container {
    
    div[class*="-container"] {
      width: 250px;
      z-index: 101;
    }
    div[class*="-control"] {
      width: 250px;
    }
  }

  &.deal-status-container {
    div[class*="-container"] {
      width: 250px;
    }
    div[class*="-control"] {
      width: 250px;
    }
  }

  .DayPickerInput {
    input {
      width: 100px;
    }
  }

  .DayPickerInput-OverlayWrapper {
    z-index: 104;
  }
`
const ButtonsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 8px;
`

const ButtonComponent = styled.div`
display: inline-flex;
align-items: center;
justify-content: center;
padding: 8px 16px;
font-size: 14px;
font-weight: 500;
line-height: 1;
border-radius: 2px;
text-align: center;
cursor: pointer;
transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
color: #fff;
background-color: #1890ff;
border: 1px solid #1890ff;
height: 32px;
`

const SOURCE_LIST = [{
  label: "Radius Marketplace",
  value: "radius_marketplace",
}, {
  label: "Zillow (Agent's Personal Account)",
  value: "zillow_agent_personal_account",
}, {
  label: "Zillow (Radius Provided)",
  value: "zillow_radius_provided",
}, {
  label: "Zillow Flex",
  value: "zillow_flex",
}, {
  label: "OpCity/Realtor.com",
  value: "opcity_realtor_com",
}, {
  label: "Team/Mentor Lead",
  value: "team_mentor_lead",
}, {
  label: "Self",
  value: "self",
}, {
  label: "Sphere of Influence/Personal",
  value: "sphere_of_influence",
},
{
  label: "Others",
  value: "other",
}, {
  label: "FSBO",
  value: "fsbo",
}, {
  label: "Mail",
  value: "mail",
}, {
  label: "Networking",
  value: "networking",
}, {
  label: "New Construction",
  value: "new_construction",
}, {
  label: "Open House",
  value: "open_house",
}, {
  label: "Other - Agency",
  value: "other_agency",
}, {
  label: "Other - Builder",
  value: "other_builder",
}, {
  label: "Paid Marketing - Farming/Direct",
  value: "paid_marketing_farming_direct",
}, {
  label: "Paid Marketing - Other",
  value: "paid_marketing_other",
}, {
  label: "Paid Marketing - Zillow",
  value: "paid_marketing_zillow",
}, {
  label: "Personal Transaction",
  value: "personal_transaction",
}, {
  label: "Property Management",
  value: "property_management",
}, {
  label: "Floor Call",
  value: "floor_call",
}, {
  label: "Referral - Attorney",
  value: "referral_attorney",
}, {
  label: "Referral - From Past Client",
  value: "referral_from_past_client",
}, {
  label: "Referral - Lendor",
  value: "referral_lendor",
}, {
  label: "Referral - Other",
  value: "referral_other",
}, {
  label: "Referral - Real Estate Agent(External)",
  value: "referral_real_estate_agent_external",
}, {
  label: "Referral - Sphere of Influence",
  value: "referral_sphere_of_influence",
}, {
  label: "Referral - Title",
  value: "referral_title",
}, {
  label: "Referrals (Agent/Lender)",
  value: "referrals_agent_Lender",
}, {
  label: "Sign Call",
  value: "sign_call",
}, {
  label: "Social Profile - Facebook",
  value: "social_profile_facebook",
}, {
  label: "Social Profile - Instagram",
  value: "social_profile_instagram",
}, {
  label: "Social Profile - Other",
  value: "social_profile_other",
}, {
  label: "Events - Other",
  value: "events_other",
}]

const CLIENT_TYPE_LIST = [
  { label: "Seller", value: "seller" },
  { label: "Buyer", value: "buyer" },
  { label: "Landlord", value: "landlord" },
  { label: "Tenant", value: "tenant" },
  { label: "Dual", value: "dual" },
  { label: "Other", value: "other" },
]

const TC_STATE = [
  { label: "All States", value: "all-states" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Texas", value: "Texas" },
  { label: "Florida", value: "Florida" },
  { label: "Washington", value: "Washington" },
  { label: "Georgia", value: "Georgia" },
  { label: "Oregon", value: "Oregon" },
  { label: "Others", value: "others" },
]

const PLAN_LIST = [
  { label: "Legacy", value: "legacy" },
  { label: "Base", value: "base" },
  { label: "Professional", value: "professional" },
  { label: "Elite", value: "elite" },
  { label: "90/10", value: "90/10" },
  { label: "95/5", value: "95/5" },
  { label: "Not Subscribed", value: "Not Subscribed" },
]

const DEAL_TYPE = [
  { label: "All Contracts", value: 1 },
  { label: "All Listings", value: 0 },
]

const DEAL_STATUS = [
  // {
  //   label: "Listing Status",
  //   options: [
  //     { label: "Incomplete", value: "incomplete" },
  //     { label: "Listing Verified", value: "listing_verified" },
  //     { label: "On Market", value: "active_listing" },
  //     { label: "Withdrawn", value: "listing_canceled" },
  //     { label: "Expired", value: "listing_expired" },
  //     { label: "Coming Soon", value: "coming_soon" },
  //     { label: "Pending", value: "pending" },
  //   ],
  // },
  {
    label: "Contract Status",
    options: [
      { label: "Incomplete Contract", value: "incomplete" },
      { label: "Contract Submitted", value: "submitted" },
      { label: "Audit Verified", value: "audit_verified" },
      { label: "Demand Pending", value: "demand_pending" },
      { label: "Demand Completed", value: "demand_completed" },
      { label: "Closed", value: "closed" },
      { label: "Agent Commission Initiated", value: "agent_commission_initiated" },
      { label: "Gross Commission Received", value: "gross_commission_received" },
      { label: "Commission Finalized", value: "payment_received" },
      { label: "Canceled", value: "contract_canceled" },
      { label: "All", value: "" },
    ],
  },
  {
    label: "Archived",
    options: [{ label: "Archived", value: "archived" }],
  },
]

const PAGE_SIZE = 30

const INIT_STATE = {
  isShowActive: false,
  searchText: "",
  searchId: "",
  clientType: null,
  teamId: null,
  sourceType: null,
  planCategory: null,
  agent_state: null,
  property_state: null,
  city: null,
  county: null,
  tc_id: null,
  listingAddress: "",
  transaction_type: null,
  radius_transaction_type: null,
  fromAcceptance: undefined,
  toAcceptance: undefined,
  fromLicenseTransferredDate: undefined,
  toLicenseTransferredDate: undefined,
  dealType: null,
  viewContract: null,
  fromSubmission: undefined,
  toSubmission: undefined,
  fromDealCreated: null,
  toDealCreated: null,
  fromDealCreatedDisplay: null,
  toDealCreatedDisplay: null,
  fromListing: undefined,
  toListing: undefined,
  isShowActiveListing: false,
  closedFromDate: undefined,
  closedToDate: undefined,
  primarySaleRepresentative: "",
  secondarySaleRepresentative: "",
  selectedFile: null,
  showLoader: false,
  sortData: {
    createdAtSortOrder: false,
    rowCreatedAtSortOrder: false,
  },
}

class TCListing extends React.PureComponent {
  state = {
    ...INIT_STATE,
  }

  handleOk = async () => {
    const { searchId, selectedFile } = this.state
    if (!selectedFile || !searchId) {
      message.error("Please select both Agent and File")
      return // Stop further execution
    }
    if (selectedFile && searchId) {
      this.setState({ showLoader: true })
      try {
        await this.uploadCSV(selectedFile, searchId)
        this.setState({
          searchText: " ",
          searchId: " ",
          selectedFile: null,
          showLoader: false,
        })
      } catch (error) {
        console.error("Error occurred during upload:", error)
        // Handle the error, if needed
        this.setState({ showLoader: false })
      }
    }
  }

  handleCancel = () => {
    const { toggleModalView } = this.props
    this.setState({
      searchText: " ",
      searchId: " ",
      selectedFile: null,
    })
    toggleModalView(false)
  }

  handleUpload = (file) => {
    this.setState({ selectedFile: file })
  }

  handleRemove = () => {
    this.setState({ selectedFile: null })
  }

  redirectToGoogleSheets = () => {
    // Redirect to the Google Sheets link when the button is clicked
    root.open("https://docs.google.com/spreadsheets/d/153HVvYEFoQoUIbyM9q0orOJFwrAFc96exHAo0nDdqUQ/copy", "_blank")
  }

  componentDidMount() {
    const {
      getAllDealsListing, tcOverview, getTCList,
      getTCListResponse, TCListfinal, getRARTeamList,
    } = this.props
    getAllDealsListing({
      type: "global",
      limit: PAGE_SIZE,
      skip: 0,
      transactionType: "Contract",
    })
    tcOverview({ })
    getTCList()
    this.checkForMemoryOverLoad()
    getRARTeamList({ skip: 0, limit: PAGE_SIZE })
  }

  uploadCSV = (file, searchId) => {
    const { uploadCSVtoS3 } = this.props
    const location = "agent-transactions"
    const timestamp = Date.now()
    const file_name = `${timestamp}_${file.name}`

    uploadCSVtoS3({
      file,
      objName: file_name,
      fileName: file_name,
      bucketName: process.env.NODE_ENV === "staging" ? `s-${location}` : location,
      agentId: searchId,
      uploadType: "Contract",
    })
  }

  getTeamSelectOptions = () => {
    const { rarTeamListResponse } = this.props
    return (rarTeamListResponse && !isEmpty(rarTeamListResponse.data)) ? rarTeamListResponse.data.map(dt => ({
      name: dt.name,
      id: dt.id,
    })) : []
  }

  checkForMemoryOverLoad = () => {
    try {
      const currentSize = new Blob(Object.values(window.localStorage)).size
      const limit = 100000 * 5 // 5mb
      const memoryLoad = (currentSize / limit) * 100
      console.log(`Current Memory Load ${memoryLoad}%`)
      if (memoryLoad > 80) {
        window.localStorage.clear()
      }
    } catch (error) {
      console.log(error)
    }
  }

  searchHandler = () => {
    const {
      tcOverview,
      getAllDealsListing,
      clearTcList,
    } = this.props
    const {
      isShowActiveListing,
      city,
    } = this.state
    clearTcList()
    const params = {
      ...this.getFilterParams(),
      limit: PAGE_SIZE,
      isMerge: false,
      transactionType: "Contract",
    }
    tcOverview(params)
    getAllDealsListing(params)
    this.setState({
      isShowActive: isShowActiveListing,
    })
  }

  handleSearchType = (value) => {
    const { rosterFetchAgentSearch } = this.props
    this.setState({ searchText: value })
    rosterFetchAgentSearch({ search: value })
  }

  setClientType = (value) => {
    this.setState({ clientType: value })
  }

  setTeamId = (value) => {
    this.setState({ teamId: value })
  }

  setAgentState = (value) => {
    this.setState({ agent_state: value })
  }

  setPropertyState = (value) => {
    this.setState({ property_state: value })
  }

  setCity = (location) => {
    this.setState({ city: get(location, "locality.long_name") })
  }

  setCounty = (location) => {
    const locationCounty = get(location, "administrative_area_level_2.long_name")
    this.setState({ county: locationCounty.split(" County")?.[0] })
  }

  setTCId = (value) => {
    this.setState({ tc_id: value })
  }

  setListingAddress = (value) => {
    this.setState({ listingAddress: value })
  }

  setTransactionType = (value) => {
    this.setState({ transaction_type: value })
  }

  setRadiusTransactionType = (value) => {
    this.setState({ radius_transaction_type: value })
  }

  setDealStatus = (label, value) => {
    this.setState({ dealType: value })
  }

  setDealType = (value) => {
    this.setState({ viewContract: value })
  }

  setSourceType = (value) => {
    this.setState({ sourceType: value })
  }

  setPrimarySaleRepresentative = (value) => {
    this.setState({ primarySaleRepresentative: value })
  }

  setSecondarySaleRepresentative = (value) => {
    this.setState({ secondarySaleRepresentative: value })
  }

  setPlanCategory = (value) => {
    this.setState({ planCategory: value })
  }

  setFromAcceptance = (day) => {
    try {
      this.setState({ fromAcceptance: moment(day.setHours(0, 0, 0)).unix() })
    } catch (error) {
      this.setState({ fromAcceptance: undefined })
    }
  }

  setToAcceptance = (day) => {
    try {
      this.setState({ toAcceptance: moment(day.setHours(0, 0, 0)).unix() })
    } catch (error) {
      this.setState({ toAcceptance: undefined })
    }
  }

  setFromLicenseTransferredDate = (day) => {
    try {
      this.setState({ fromLicenseTransferredDate: moment(day.setHours(0, 0, 0)).unix() })
    } catch (error) {
      this.setState({ fromLicenseTransferredDate: undefined })
    }
  }

  setToLicenseTransferredDate = (day) => {
    try {
      this.setState({ toLicenseTransferredDate: moment(day.setHours(0, 0, 0)).unix() })
    } catch (error) {
      this.setState({ toLicenseTransferredDate: undefined })
    }
  }

  setFromSubmission = (day) => {
    try {
      this.setState({ fromSubmission: moment(day.setHours(0, 0, 0)).format("YYYY-MM-DD HH:MM:SS") })
    } catch (error) {
      this.setState({ fromSubmission: undefined })
    }
  }

  setToSubmission = (day) => {
    try {
      this.setState({ toSubmission: moment(day.setHours(0, 0, 0)).format("YYYY-MM-DD HH:MM:SS") })
    } catch (error) {
      this.setState({ toSubmission: undefined })
    }
  }

  setFromDealCreation = (date) => {
    try {
      console.log("from date", date)
      this.setState({ fromDealCreatedDisplay: moment(date.setHours(0, 0, 0)).format("YYYY-MM-DD HH:MM:SS") })
      const epoch = moment(date).startOf("day").unix()
      console.log("from date epoch", epoch)
      this.setState({ fromDealCreated: epoch })
    } catch (error) {
      this.setState({ fromDealCreated: null })
    }
  }

  setToDealCreation = (date) => {
    try {
      this.setState({ toDealCreatedDisplay: moment(date.setHours(0, 0, 0)).format("YYYY-MM-DD HH:MM:SS") })
      const epoch = moment(date).startOf("day").unix()
      this.setState({ toDealCreated: epoch })
    } catch (error) {
      this.setState({ toDealCreated: null })
    }
  }

  setFromListing = (day) => {
    try {
      this.setState({ fromListing: moment(day.setHours(0, 0, 0)).format("YYYY-MM-DD HH:MM:SS") })
    } catch (error) {
      this.setState({ fromListing: undefined })
    }
  }

  setToListing = (day) => {
    try {
      this.setState({ toListing: moment(day.setHours(0, 0, 0)).format("YYYY-MM-DD HH:MM:SS") })
    } catch (error) {
      this.setState({ toListing: undefined })
    }
  }

  getFilterParams = () => {
    const clientType = get(this.state, "clientType.value")
    const teamId = get(this.state, "teamId")
    const searchId = get(this.state, "searchId")
    const sourceType = get(this.state, "sourceType.value")
    const planCategory = get(this.state, "planCategory.value")
    const agent_state = get(this.state, "agent_state.value")
    const property_state = get(this.state, "property_state.value")
    const city = get(this.state, "city")
    const county = get(this.state, "county")
    const primarySaleRepresentative = get(this.state, "primarySaleRepresentative.value")
    const secondarySaleRepresentative = get(this.state, "secondarySaleRepresentative.value")
    const tc_id = get(this.state, "tc_id.value")
    const listingAddress = get(this.state, "listingAddress")
    const transaction_type = get(this.state, "transaction_type.value")
    const radius_transaction_type = get(this.state, "radius_transaction_type.value")
    const fromAcceptance = get(this.state, "fromAcceptance")
    const toAcceptance = get(this.state, "toAcceptance")
    const fromLicenseTransferredDate = get(this.state, "fromLicenseTransferredDate")
    const toLicenseTransferredDate = get(this.state, "toLicenseTransferredDate")
    const fromSubmission = get(this.state, "fromSubmission")
    const toSubmission = get(this.state, "toSubmission")
    const fromDealCreated = get(this.state, "fromDealCreated")
    const toDealCreated = get(this.state, "toDealCreated")
    const fromListing = get(this.state, "fromListing")
    const toListing = get(this.state, "toListing")
    const isShowActive = get(this.state, "isShowActive")
    const sortData = get(this.state, "sortData")
    const isShowActiveListing = get(this.state, "isShowActiveListing")
    const dealType = get(this.state, "dealType.value")
    const viewContract = get(this.state, "viewContract.value")
    const { dealsListingResponse } = this.props
    const alreadyFetched = get(dealsListingResponse, "data.length")
    const closedFromDate = get(this.state, "closedFromDate")
    const closedToDate = get(this.state, "closedToDate")
    const payload = {
      type: "global",
      clientType,
      teamId,
      searchId,
      sourceType,
      planCategory,
      agent_state,
      property_state,
      city,
      county,
      primarySaleRepresentative,
      secondarySaleRepresentative,
      tc_id,
      listingAddress,
      transaction_type,
      radius_transaction_type,
      dealType,
      viewContract,
      fromAcceptance,
      toAcceptance,
      fromLicenseTransferredDate,
      toLicenseTransferredDate,
      fromSubmission,
      toSubmission,
      fromDealCreated,
      toDealCreated,
      fromListing,
      toListing,
      sortData,
      isShowActive,
      limit: alreadyFetched ? alreadyFetched + PAGE_SIZE : PAGE_SIZE,
      isShowActiveListing,
      startDate: closedFromDate,
      endDate: closedToDate,
    }
    console.log("payload", payload)
    return payload
  }

  handleSortLick = (sortKey, dir) => {
    const { getAllDealsListing } = this.props
    this.setState({
      sortData: {
        [sortKey]: dir === "ASC" ? "DESC" : "ASC",
      },
    }, () => {
      getAllDealsListing(this.getFilterParams())
    })
  }

  handleLoadMore = () => {
    const { getAllDealsListing, dealsListingResponse } = this.props
    const alreadyFetched = get(dealsListingResponse, "data.length")
    if (alreadyFetched && dealsListingResponse && !dealsListingResponse.isFetching) {
      getAllDealsListing({
        ...this.getFilterParams(),
        limit: alreadyFetched + PAGE_SIZE,
        skip: alreadyFetched,
        isMerge: true,
        transactionType: "Contract",
      })
    }
  }

  convertToDate = (date) => {
    if (date) {
      return unixToDate(date)
    }
    return null
  }

  handleClear = () => {
    const { getAllDealsListing, tcOverview } = this.props
    this.setState({
      ...INIT_STATE,
    })
    getAllDealsListing({
      type: "global",
      limit: PAGE_SIZE,
      skip: 0,
      transactionType: "Contract",
    })
    tcOverview({})
  }

  handleDownloadButton = () => {
    const {
      tcDataDownload,
    } = this.props

    const params = {
      ...this.getFilterParams(),
      limit: PAGE_SIZE,
      isMerge: false,
    }

    tcDataDownload(params)
  }

  handleRefreshPage = () => {
    root.location.reload()
  }

  addListings = () => {
    const {
      history, setDealAgentId, setDealToView, resetListings,
    } = this.props
    // const user = cookie.load("user")
    resetListings()
    setDealAgentId(null)
    setDealToView(null)
    history.push("/broker/tc/add-listings/coversheet/listing-agent-info")
  }

  addOffers = () => {
    const { history } = this.props
    history.push("/broker/tc/offers")
  }

  //show dahsboard
  showListingsDashboard = () => {
    const { history } = this.props
    history.push("/broker/tc/listings")
  }

  triggerFetchDeals = () => {
    const { getAllDealsListing } = this.props
    getAllDealsListing({
      type: "global",
      limit: PAGE_SIZE,
      skip: 0,
      transactionType: "Contract",
    })
  }

  render() {
    const {
      isShowActive,
      searchText,
      searchId,
      isShowActiveListing,
      clientType,
      teamId,
      sourceType,
      planCategory,
      agent_state,
      property_state,
      city,
      county,
      tc_id,
      listingAddress,
      transaction_type,
      dealType,
      viewContract,
      fromAcceptance,
      toAcceptance,
      fromLicenseTransferredDate,
      toLicenseTransferredDate,
      fromSubmission,
      toSubmission,
      fromDealCreated,
      toDealCreated,
      fromDealCreatedDisplay,
      toDealCreatedDisplay,
      fromListing,
      toListing,
      closedFromDate,
      closedToDate,
      radius_transaction_type,
      primarySaleRepresentative,
      secondarySaleRepresentative,
      selectedFile,
      showLoader,
    } = this.state
    const {
      dealsListingResponse,
      tcOverviewResponse,
      history,
      TCListfinal,
      tcLoader,
      agents,
      isAgentListDropdownVisible,
      toggleAgentList,
      isModalViewOpen,
      toggleModalView,
    } = this.props
    const { isFetching: isFetchingName, data: dataAgent } = agents
    const agentsLists = get(dataAgent, "agents", [])
    const { isFetching, data: listingData, isFetchingMore } = dealsListingResponse
    const { data: overviewData } = tcOverviewResponse || {}
    const fetchedSize = get(listingData, "length") || 0
    return (
      <Wrap>
        <Header>
          {overviewData && overviewData.overview && (
            <OverviewTC
              overview={overviewData.overview}
            />
          )}
        </Header>
        <Header>
          <Row gutter={10}>
            <Col flex={1}>
              <Collapse defaultActiveKey={["0"]}>
                <Collapse.Panel key="1" header="Filters">
                  <Header style={{ margin: 0, zIndex: 2 }}>
                    <Row>
                      <Col>
                        <MaxMinWrapper className="agent-type-container">
                          <h6>Property Address</h6>
                          <Input
                            type="text"
                            value={listingAddress}
                            placeholder="Street, City, State"
                            // onChange={(e) => { this.setListingAddress(e.target.value) }}
                            onChange={(e) => { this.setListingAddress(e.target.value) }}
                          />
                        </MaxMinWrapper>
                      </Col>
                      <WrapAgent>
                        <h4>Agent Name Or Email</h4>
                        <div>
                          <Input
                            style={{ width: 300 }}
                            value={searchText}
                            placeholder="Search agent"
                            onChange={(e) => {
                              toggleAgentList(true)
                              this.handleSearchType(e.target.value)
                            }}
                          />
                          <AgentList
                            hideDropdown={!isAgentListDropdownVisible}
                            hasAgents={agentsLists && agentsLists.length > 0}
                          >
                            {isFetchingName ? (
                              <Loader width="25px" top={0} bottom={0} />
                            ) : (
                              <React.Fragment>
                                {searchText && agentsLists && agentsLists.length > 0 && agentsLists.map(agent => (
                                  <AgentItem
                                    key={agent.id}
                                    onClick={() => {
                                      this.setState({ searchText: agent.email })
                                      this.setState({ searchId: agent.id })
                                      toggleAgentList(false)
                                      // setSearchActive(true)
                                    }}
                                  >
                                    <div>
                                      <img src={agent.agent_image} alt="" />
                                    </div>
                                    <div>
                                      <p>
                                        {`${agent.firstname} ${agent.lastname}`}
                                      </p>
                                      <h6>
                                        {agent.email}
                                      </h6>
                                      <NumberFormat format="+1 (###) ###-####" mask="_" value={agent.phone} />
                                    </div>
                                  </AgentItem>
                                ))}
                              </React.Fragment>
                            )}
                          </AgentList>
                        </div>
                      </WrapAgent>
                      <Col>
                        <MaxMinWrapper className="client-type-container">
                          <h6>Client Type</h6>
                          <Select
                            isMulti={false}
                            style={{ zIndex: 100, margin: "0px 20px" }}
                            options={CLIENT_TYPE_LIST}
                            placeholder="Select Client Type"
                            value={clientType}
                            onChange={(value) => { this.setClientType(value) }}
                          />
                        </MaxMinWrapper>
                      </Col>
                      <Col>
                        <MaxMinWrapper className="source-container">
                          <h6>Team Name</h6>
                          <MultipleSelect
                            style={{ width: 220 }}
                            size="large"
                            placeholder="Select Team"
                            value={teamId}
                            onChange={(value) => {
                              this.setTeamId(value)
                            }}
                          >
                            {this.getTeamSelectOptions().map(option => (
                              <Option value={option.id}>{option.name}</Option>
                            ))}
                          </MultipleSelect>
                        </MaxMinWrapper>
                      </Col>
                      <Col>
                        <MaxMinWrapper className="source-container">
                          <h6>Source</h6>
                          <Select
                            isMulti={false}
                            style={{ zIndex: 100, margin: "0px 20px" }}
                            options={SOURCE_LIST}
                            placeholder="Select Source"
                            value={sourceType}
                            onChange={(value) => { this.setSourceType(value) }}
                          />
                        </MaxMinWrapper>
                      </Col>
                      <Col>
                        <MaxMinWrapper className="source-container">
                          <h6>Plan Category</h6>
                          <Select
                            isMulti={false}
                            style={{ zIndex: 100, margin: "0px 20px" }}
                            options={PLAN_LIST}
                            placeholder="Select Plan"
                            value={planCategory}
                            onChange={(value) => { this.setPlanCategory(value) }}
                          />
                        </MaxMinWrapper>
                      </Col>
                      <Col>
                        <MaxMinWrapper style={{ marginRight: 20 }}>
                          <h6>Date of Contract</h6>
                          <div>
                            <DayPickerInput
                              type="text"
                              onDayChange={(day) => { this.setFromAcceptance(day) }}
                              placeholder="From"
                              style={{ width: 100, marginRight: 20 }}
                              value={this.convertToDate(fromAcceptance)}
                            />
                            <DayPickerInput
                              type="text"
                              onDayChange={(day) => { this.setToAcceptance(day) }}
                              style={{ width: 100 }}
                              placeholder="To"
                              value={this.convertToDate(toAcceptance)}
                            />
                          </div>
                        </MaxMinWrapper>
                      </Col>
                      <Col>
                        <MaxMinWrapper style={{ marginRight: 20 }}>
                          <h6>License Transferred Date</h6>
                          <div>
                            <DayPickerInput
                              type="text"
                              onDayChange={(day) => { this.setFromLicenseTransferredDate(day) }}
                              placeholder="From"
                              style={{ width: 100, marginRight: 20 }}
                              value={this.convertToDate(fromLicenseTransferredDate)}
                            />
                            <DayPickerInput
                              type="text"
                              onDayChange={(day) => { this.setToLicenseTransferredDate(day) }}
                              style={{ width: 100 }}
                              placeholder="To"
                              value={this.convertToDate(toLicenseTransferredDate)}
                            />
                          </div>
                        </MaxMinWrapper>
                      </Col>
                      <Col>
                        <MaxMinWrapper style={{ marginRight: 20 }}>
                          <h6>Contract Creation Date</h6>
                          <div>
                            <DayPickerInput
                              type="text"
                              onDayChange={(day) => { this.setFromDealCreation(day) }}
                              placeholder="From"
                              style={{ width: 100, marginRight: 20 }}
                              value={fromDealCreatedDisplay}
                            />
                            <DayPickerInput
                              type="text"
                              onDayChange={(day) => { this.setToDealCreation(day) }}
                              style={{ width: 100 }}
                              placeholder="To"
                              value={toDealCreatedDisplay}
                            />
                          </div>
                        </MaxMinWrapper>
                      </Col>
                      <Col>
                        <MaxMinWrapper style={{ marginRight: 20 }}>
                          <h6>Contract Submission Date</h6>
                          <div>
                            <DayPickerInput
                              type="text"
                              onDayChange={(day) => { this.setFromSubmission(day) }}
                              placeholder="From"
                              style={{ width: 100, marginRight: 20 }}
                              value={fromSubmission}
                            />
                            <DayPickerInput
                              type="text"
                              onDayChange={(day) => { this.setToSubmission(day) }}
                              style={{ width: 100 }}
                              placeholder="To"
                              value={toSubmission}
                            />
                          </div>
                        </MaxMinWrapper>
                      </Col>
                      <Col>
                        <MaxMinWrapper className="agent-type-container">
                          <h6>Agent State</h6>
                          <Select
                            isMulti={false}
                            style={{ zIndex: 100, margin: "0px 20px" }}
                            options={TC_STATE}
                            placeholder="Select State"
                            onChange={(value) => { this.setAgentState(value) }}
                            value={agent_state}
                          />
                        </MaxMinWrapper>
                      </Col>
                      <Col>
                        <MaxMinWrapper className="agent-type-container">
                          <h6>Property State</h6>
                          <Select
                            isMulti={false}
                            style={{ zIndex: 100, margin: "0px 20px" }}
                            options={TC_STATE}
                            placeholder="Select State"
                            onChange={(value) => { this.setPropertyState(value) }}
                            value={property_state}
                          />
                        </MaxMinWrapper>
                      </Col>
                      <Col>
                        <MaxMinWrapper className="agent-type-container">
                          <h6>Property City</h6>
                          <GoogleAutoComplete
                            id="cities"
                            types={["(cities)"]}
                            name="cities"
                            // formValue={cityFormValue[`city-${index}`]}
                            onChange={(e) => { this.setState({ city: e.target.value }) }}
                            location={(loc) => { this.setCity(loc) }}
                            value={city}
                            placeholder=""
                            styles={{
                              height: "30px",
                              borderRadius: "0px",
                              background: "white",
                              fontSize: "16px",
                              marginTop: "10px",
                            }}
                            restrict={{
                              lat: 37.09024,
                              lng: -95.712891,
                              south_west: {
                                lat: 25.82,
                                lng: -124.39,
                              },
                              north_east: {
                                lat: 49.38,
                                lng: -66.94,
                              },
                              address: "United States",
                              country: {
                                long_name: "United States",
                                short_name: "US",
                                types: [
                                  "country",
                                  "political",
                                ],
                              },
                            }}
                          />
                        </MaxMinWrapper>
                      </Col>
                      <Col>
                        <MaxMinWrapper className="agent-type-container">
                          <h6>Property County</h6>
                          <GoogleAutoComplete
                            id="counties"
                            types={["(regions)"]}
                            name="counties"
                            onChange={(e) => { this.setState({ county: e.target.value }) }}
                            location={(loc) => { this.setCounty(loc) }}
                            value={county}
                            placeholder=""
                            styles={{
                              height: "30px",
                              borderRadius: "0px",
                              background: "white",
                              fontSize: "16px",
                              marginTop: "10px",
                            }}
                            restrict={{
                              lat: 37.09024,
                              lng: -95.712891,
                              south_west: {
                                lat: 25.82,
                                lng: -124.39,
                              },
                              north_east: {
                                lat: 49.38,
                                lng: -66.94,
                              },
                              address: "United States",
                              country: {
                                long_name: "United States",
                                short_name: "US",
                                types: [
                                  "country",
                                  "political",
                                ],
                              },
                            }}
                          />
                        </MaxMinWrapper>
                      </Col>
                      <Col>
                        <MaxMinWrapper className="agent-type-container">
                          <h6>TC Assigned</h6>
                          <Select
                            isMulti={false}
                            style={{ zIndex: 100, margin: "0px 20px" }}
                            options={TCListfinal}
                            placeholder="Select TC Assigned"
                            onChange={(value) => { this.setTCId(value) }}
                            value={tc_id}
                          />
                        </MaxMinWrapper>
                      </Col>
                      <Col>
                        <MaxMinWrapper className="transaction-type-container">
                          <h6>Transaction Type</h6>
                          <Select
                            isMulti={false}
                            style={{ zIndex: 100, margin: "0px 20px" }}
                            options={TRANSACTION_TYPE}
                            placeholder="Select Transaction Type"
                            onChange={(value) => { this.setTransactionType(value) }}
                            value={transaction_type}
                          />
                        </MaxMinWrapper>
                      </Col>
                      <Col>
                        <MaxMinWrapper className="deal-status-container">
                          <h6>Status</h6>
                          <MultipleSelect
                            style={{ width: 250, borderRadius: 90 }}
                            options={DEAL_STATUS}
                            placeholder="Select"
                            onChange={(label, value) => { this.setDealStatus(label, value) }}
                            value={dealType}
                          />
                        </MaxMinWrapper>
                      </Col>
                      {/* <Col>
                        <MaxMinWrapper className="deal-status-container">
                          <h6>Deal Type</h6>
                          <Select
                            style={{ width: 250, borderRadius: 90 }}
                            options={DEAL_TYPE}
                            placeholder="Select"
                            onChange={(label, value) => { this.setDealType(label, value) }}
                            value={viewContract}
                          />
                        </MaxMinWrapper>
                      </Col> */}
                      <Col>
                        <MaxMinWrapper className="transaction-type-container">
                          <h6>Radius Transaction Type</h6>
                          <Select
                            isMulti={false}
                            style={{ zIndex: 100, margin: "0px 20px" }}
                            options={RADIUS_TRANSACTION_TYPE}
                            placeholder="Select"
                            onChange={(value) => { this.setRadiusTransactionType(value) }}
                            value={radius_transaction_type}
                          />
                        </MaxMinWrapper>
                      </Col>
                      <Col>
                        <MaxMinWrapper style={{ zIndex: 100 }}>
                          <h6>Primary Sales Representative</h6>
                          <div>
                            <Select
                              width={200}
                              isMulti={false}
                              style={{ zIndex: 100, margin: "0px 20px" }}
                              options={SALES_REPRESENTATIVE}
                              value={primarySaleRepresentative}
                              placeholder="Select"
                              onChange={(value) => {
                                this.setPrimarySaleRepresentative(value)
                              }}
                            />
                          </div>
                        </MaxMinWrapper>
                      </Col>
                      <Col>
                        <MaxMinWrapper style={{ zIndex: 100 }}>
                          <h6>Secondary Sales Representative</h6>
                          <div>
                            <Select
                              width={200}
                              isMulti={false}
                              style={{ zIndex: 100, margin: "0px 20px" }}
                              options={SALES_REPRESENTATIVE}
                              value={secondarySaleRepresentative}
                              placeholder="Select"
                              onChange={(value) => {
                                this.setSecondarySaleRepresentative(value)
                              }}
                            />
                          </div>
                        </MaxMinWrapper>
                      </Col>
                      <Col>
                        <MaxMinWrapper>
                          <h6>Closed Date Range</h6>
                          <div>
                            <DayPickerInput
                              type="text"
                              onDayChange={(day) => {
                                this.setState({
                                  closedFromDate: (new Date(day)).getTime() / 1000,
                                })
                              }}
                              placeholder="From"
                              style={{ width: 100, marginRight: 20 }}
                              value={this.convertToDate(closedFromDate)}
                            />
                            <DayPickerInput
                              type="text"
                              onDayChange={(day) => {
                                this.setState({
                                  closedToDate: (new Date(day)).getTime() / 1000,
                                })
                              }}
                              style={{ width: 100 }}
                              placeholder="To"
                              value={this.convertToDate(closedToDate)}
                            />
                          </div>
                        </MaxMinWrapper>

                      </Col>
                      {/* <Col>
                        <MaxMinWrapper>
                          <h6>Show Active Listing</h6>
                          <div>
                            <Toggle isChecked={isShowActiveListing} onChange={() => { this.setState({ isShowActiveListing: !isShowActiveListing }) }}/>
                          </div>
                        </MaxMinWrapper>
                      </Col> */}
                      <Col>
                        <Button style={{ marginTop: 33 }} type="primary" size="large" onClick={this.searchHandler}>
                          Filter
                        </Button>
                      </Col>
                      <Col>
                        <Button style={{ marginTop: 33 }} type="link" size="large" onClick={this.handleClear}>
                          Clear
                        </Button>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col flex={1}>
                      </Col>
                    </Row>
                  </Header>
                </Collapse.Panel>
              </Collapse>
            </Col>
          </Row>
          <ButtonsDiv>
            <Col>
              <Button type="primary" title="Download In-Contract and Closed Deals Data" onClick={this.handleDownloadButton}>
                Export Data to CSV
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={() => { history.push("/broker/tc/add") }}>
                Add Contracts
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={() => { this.addListings() }}>
                Add Listings
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={() => { this.addOffers() }}>
                View All Offers
              </Button>
            </Col>
            <Col>
              <ButtonComponent onClick={() => toggleModalView(true)}>
              Import Contract CSV on Soul
              </ButtonComponent>
            </Col>
            <Col>
              <ButtonComponent type="primary" onClick={this.redirectToGoogleSheets}>
                Create CSV
              </ButtonComponent>
            </Col>
            <Modal
              title="Select Agent and File"
              visible={isModalViewOpen}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              okText="Upload"
            >
              <Col>
                <WrapAgent>
                  <h4>Agent Name Or Email</h4>
                  <div>
                    <Input
                      style={{ width: 300, marginBottom: "16px" }}
                      value={searchText}
                      placeholder="Search agent"
                      onChange={(e) => {
                        toggleAgentList(true)
                        this.handleSearchType(e.target.value)
                      }}
                    />
                    <AgentList
                      hideDropdown={!isAgentListDropdownVisible}
                      hasAgents={agentsLists && agentsLists.length > 0}
                    >
                      {isFetchingName ? (
                        <Loader width="25px" top={0} bottom={0} />
                      ) : (
                        <React.Fragment>
                          {searchText && agentsLists && agentsLists.length > 0 && agentsLists.map(agent => (
                            <AgentItem
                              key={agent.id}
                              onClick={() => {
                                this.setState({ searchText: agent.email })
                                this.setState({ searchId: agent.id })
                                toggleAgentList(false)
                                // setSearchActive(true)
                              }}
                            >
                              <div>
                                <img src={agent.agent_image} alt="" />
                              </div>
                              <div>
                                <p>
                                  {`${agent.firstname} ${agent.lastname}`}
                                </p>
                                <h6>
                                  {agent.email}
                                </h6>
                                <NumberFormat format="+1 (###) ###-####" mask="_" value={agent.phone} />
                              </div>
                            </AgentItem>
                          ))}
                        </React.Fragment>
                      )}
                    </AgentList>
                  </div>
                </WrapAgent>
              </Col>
              <Col>
                <Upload
                  accept=".csv"
                  fileList={selectedFile ? [selectedFile] : []}
                  onRemove={this.handleRemove}
                  showUploadList={{
                    showRemoveIcon: true,
                  }}
                  beforeUpload={this.handleUpload}
                  action={file => this.handleUpload(file)}
                >
                  <Col>
                    <Button type="primary" icon={<UploadOutlined />}>
                      Select File
                    </Button>
                  </Col>
                </Upload>
                {showLoader && <Spin style={{ width: "100%" }} indicator={(<LoadingOutlined style={{ fontSize: 44, margin: "60px auto" }} spin />)} />}
              </Col>
            </Modal>
            <Col>
              <Button
              //heree
                type="primary"
                onClick={() => { this.showListingsDashboard() }}
              >
                View All Listings
              </Button>
            </Col>
          </ButtonsDiv>
        </Header>
        {((listingData && listingData.length === 0)) ? (
          <React.Fragment>
            {tcLoader ? (
              // <Loader />
              <Spin style={{ width: "100%" }} indicator={(<LoadingOutlined style={{ fontSize: 44, margin: "60px auto" }} spin />)} />
            ) : (
              <NoResults />
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Spin spinning={!!tcLoader} style={{ width: "100%", position: "fixed", top: "30%" }} indicator={(<LoadingOutlined style={{ fontSize: 44, margin: "auto" }} spin />)}>
              <DealList
                data={listingData}
                isShowActive={isShowActive}
                hasMore={overviewData && overviewData.overview && overviewData.overview.total_deals != fetchedSize}
                isLoadingMore={(!!fetchedSize && isFetching)}
                sortTCList={this.handleSortLick}
                sortData={this.state.sortData}
                loadMore={this.handleLoadMore}
                triggerFetchDeals={this.triggerFetchDeals}
                handleRefreshPage={this.handleRefreshPage}
              />
            </Spin>
          </React.Fragment>
        ) }
      </Wrap>
    )
  }
}

export default teamContainer(agentSearchContainer(globalContainer(TCListing)))
