import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Select } from "antd";
import ConsumerApp from "container/ConsumerApp/index";
import moment from "moment";
import "./styles.css";

const ViewMoreModal = ({
  isModalVisible,
  handleCancel,
  title,
  body,
  columnType,
  record_consumerId,
  viewMoreData,
  fetchProperties, 
  fetchPropertiesResponse,
  fetchSearchBoardTable,
  fetchSearchBoardTableResponse,
  fetchFamilyMembers,
  fetchFamilyMembersResponse,
}) => {

  const [pagination, setPagination] = useState({ current: 1, pageSize: 5 });
  const [propertiesData, setPropDataSource] = useState([]);
  const [searchBoardData, setSearchBoardDataSource] = useState([]);
  const [propertyType, setpropertyType] = useState(0);
  useEffect(() => {
    if (fetchSearchBoardTableResponse?.data) {
      setSearchBoardDataSource(fetchSearchBoardTableResponse.data.data);
    }
    
  }, [fetchSearchBoardTableResponse]);

  useEffect(() => {
    if(fetchPropertiesResponse?.data) {
      setPropDataSource(fetchPropertiesResponse.data.data);
    }
  }, [fetchPropertiesResponse]);

  const handleModalClose = () => {
    setPagination({ current: 1, pageSize: 10 });
    setpropertyType(0);
    handleCancel();
  }

  const handlePageChange = (page, pageSize) => {
    setPagination({ current: page, pageSize });
  };

  const consumer_PropertyDetails_columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 80,
      render: (status, record) => {
        return (
          <div>
            <p>{record.property_type}</p>
            <p style={{color: "#4B5563"}}>{record.property_sub_type}</p>
          </div>
        );
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 80,
      render: (status, record) => {
        return (
          <div>
            <p>{record.address}, {record.city}</p>
            <p style={{color: "#4B5563"}}>{record.state}</p>
          </div>
        );
      },
    },
    {
      title: "Beds",
      dataIndex: "bedrooms",
      key: "bedrooms",
      width: 80,
      render: (status, record) => {
        const beds = record.bedrooms || "-";
        return (
          <div>
            {beds === -1 ? "Studio" : beds}
          </div>
        );
      },
    },
    {
      title: "Baths",
      dataIndex: "bathrooms",
      key: "bathrooms",
      width: 80,
      render: (status, record) => {
        const baths = record.bathrooms || "-";
        return (
          <div>
            {baths}
          </div>
        );
      },
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "area",
      width: 80,
      render: (status, record) => {
        return (
          <div>
            <p>{record.area} {record.area_units}</p>
          </div>
        );
      },
    },
  ];

  const consumer_searchCriteriaDetails_columns = [
    // {
    //   title: "Title",
    //   dataIndex: "team_agent",
    //   key: "team_agent",
    //   width: 80,
    // },
    {
      title: "Locations",
      dataIndex: "locations",
      key: "locations",
      width: 80,
      render: (status, record) => {
        const locations = record.locations;
        return (
          <div>
            {locations &&
              locations.map((data, index) => {
                return data.c_locality_name ? ( // Add `return` here
                  <div key={index}>
                    <p>
                      {data.c_locality_name}, {data.city}
                    </p>
                    <p style={{ color: "#4B5563", marginBottom: "5px" }}>{record.state}</p>
                  </div>
                ) : (
                  <div key={index}>
                    <p>{data.city}</p>
                    <p style={{ color: "#4B5563", marginBottom: "5px" }}>{data.state}</p>
                  </div>
                );
              })}
          </div>
        );
      },
    },    
    {
      title: "Beds",
      dataIndex: "bedroom",
      key: "bedroom",
      width: 80,
      render: (status, record) => {
        const beds = record.bedroom || "-";
        return (
          <div>
            {beds === -1 ? "Studio" : beds}
          </div>
        );
      },
    },
    {
      title: "Baths",
      dataIndex: "bathroom",
      key: "bathroom",
      width: 80,
      render: (status, record) => {
        const baths = record.bathroom || "-";
        return (
          <div>
            {baths}
          </div>
        );
      },
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "area",
      width: 80,
      render: (text, record) => {
        return (
          <>
            <div>{record.min_size} - {record.max_size} {record.area_unit}</div>
          </>
        );
      },
    },
    {
      title: "Additional details",
      dataIndex: "notes",
      key: "notes",
      width: 80,
    },
    {
      title: "Date added",
      dataIndex: "team_agent",
      key: "team_agent",
      width: 80,
      render: (text, record) => {
        const formattedDate = moment(record.created_at).format("MM/DD/YYYY");
        return (
          <>
            <div>{formattedDate}</div>
          </>
        );
      },
    },
  ];

  const consumer_familyMemberDetails_columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 80,
    },
    {
      title: "Date added",
      dataIndex: "created_at",
      key: "created_at",
      width: 80,
      render: (text, record) => {
        const formattedDate = moment(record.created_at).format("MM/DD/YYYY");
        return (
          <>
            <div>{formattedDate}</div>
          </>
        );
      },
    },
  ];

  const searchBoard_searchDetails_columns = [
    {
      title: "Beds",
      dataIndex: "beds",
      key: "beds",
      width: 80,
      render: (status, record) => {
        const beds = record.beds || "-";
        return (
          <div>
            {beds === -1 ? "Studio" : beds}
          </div>
        );
      },
    },
    {
      title: "Baths",
      dataIndex: "baths",
      key: "baths",
      width: 80,
      render: (status, record) => {
        const baths = record.baths || "-";
        return (
          <div>
            {baths}
          </div>
        );
      },
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "area",
      width: 80,
      render: (text, record) => {
        return (
          <>
            <div>{record.min_size} - {record.max_size} {record.area_units}</div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if(columnType === "consumer_propertyDetails") {
      const { current, pageSize } = pagination;
      const payload = {
        limit: pageSize,
        skip: (current - 1) * pageSize,
        consumerId: record_consumerId,
        like_status: propertyType,
      };
      fetchProperties(payload);
    }
    if(columnType === "consumer_searchCriteriaDetails") {
      const { current, pageSize } = pagination;
      const payload = {
        limit: pageSize,
        skip: (current - 1) * pageSize,
        consumerUserId: record_consumerId,
        email: "",
        location: "",
        teamId: "",
      }
      fetchSearchBoardTable(payload);
    }
    if(columnType === "consumer_familyMemberDetails") {
      const { current, pageSize } = pagination;
      const payload = {
        limit: pageSize,
        skip: (current - 1) * pageSize,
        consumerId: record_consumerId,
      }
      fetchFamilyMembers(payload);
    }
  }, [pagination, columnType])

  const handlePropertiesStatusChanges = (value) => {
    if(value === "all") {
      setpropertyType(0);
      const payload = {
        limit: 10,
        skip: 0,
        consumerId: record_consumerId,
        like_status: 0,
      };
      fetchProperties(payload)
    }
    else if(value === "fav") {
      setpropertyType(1);
      const payload = {
        limit: 10,
        skip: 0,
        consumerId: record_consumerId,
        like_status: 1,
      };
      fetchProperties(payload)
    }
    else {
      setpropertyType(-1);
      const payload = {
        limit: 10,
        skip: 0,
        consumerId: record_consumerId,
        like_status: -1,
      };
      fetchProperties(payload)
    }
  }

  return (
    <>
      <Modal
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={handleModalClose}
        footer={null}
        width="800px"
      >
        {columnType === "consumer_propertyDetails" && (
          <>
            <div style={{display: "flex", justifyContent: "space-between", width: "95%", marginBottom: "10px"}}>
              <h1>Property details</h1>
              <Select
              defaultValue="all"
              // style={styles.inputSearch}
              onChange={handlePropertiesStatusChanges}
              options={[
                {
                  value: "all",
                  label: "All Properties",
                },
                {
                  value: "fav",
                  label: "Favorites",
                },
                {
                  value: "rejected",
                  label: "Rejected",
                },
              ]}
            />
            </div>
            <Table
              columns={consumer_PropertyDetails_columns}
              dataSource={propertiesData}
              loading={fetchPropertiesResponse?.isFetching}
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                onChange: handlePageChange,
                total: fetchPropertiesResponse?.data?.total_count || 0,
              }}
              className="styled-table"
            />
          </>
        )}
        {columnType === "consumer_searchCriteriaDetails" && (
          <>
            <h1>Search criteria details</h1>
            <Table
              columns={consumer_searchCriteriaDetails_columns}
              dataSource={searchBoardData}
              loading={fetchSearchBoardTableResponse?.isFetching}
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                onChange: handlePageChange,
                total: fetchSearchBoardTableResponse?.data?.total_count || 0,
              }}
              className="styled-table"
            />
          </>
        )}
        {columnType === "consumer_familyMemberDetails" && (
          <>
            <h1>Family member details</h1>
            <Table
              columns={consumer_familyMemberDetails_columns}
              dataSource={fetchFamilyMembersResponse.data}
              loading={fetchFamilyMembersResponse?.isFetching}
              className="styled-table"
              // pagination={{
              //   current: pagination.current,
              //   pageSize: pagination.pageSize,
              //   onChange: handlePageChange,
              //   total: fetchWaitlistTableResponse?.data?.total_count || 0,
              // }}
            />
          </>
        )}
        {columnType === "searchBoard_locationDetails" && (
          <>
            <h1>Location details</h1>
            <ol>
              {viewMoreData.map((data) => (
                <li>
                  {data.county}, {data.city}, {data.state}
                </li>
              ))}
            </ol>
          </>
        )}
        {columnType === "searchBoard_searchDetails" && (
          <>
            <h1>Search details</h1>
            <Table
              columns={searchBoard_searchDetails_columns}
              dataSource={viewMoreData}
              pagination={false}
            />
            <div style={{borderRadius: "8px", border: "1px solid var(--Global-Mapping-Gray-100, #E0E0EB)", background: "var(--Global-Mapping-Gray-50, #EFEFF4)", padding: "8px"}}>
              <p style={{margin: "0"}}>Additional Notes: {viewMoreData[0].notes}</p>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default ConsumerApp(ViewMoreModal);
