import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  Tag,
  Button,
  Space,
  Dropdown,
  Menu,
  Modal,
  Input,
  Tabs,
  DatePicker,
  Badge,
  Select,
} from "antd";
import ConsumerApp from "container/ConsumerApp/index";
import moment from "moment";
import { debounce } from "lodash";
import ViewMoreModal from "./ViewMoreModal";
import "./styles.css";

const styles = {
  tableContainer: {
    padding: "16px",
    backgroundColor: "#F9FAFB",
  },
  filterWrapper: {
    display: "flex",
  },
  header: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "16px",
    width: "500px",
  },
  filterButton: {
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    color: "#000000",
    border: "1px solid #E0E0E0",
  },
  inputSearch: {
    width: "220px",
    marginRight: "12px",
    height: "35px !important",
  },
  modalTitle: {
    fontSize: "18px",
    fontWeight: "600",
  },
  modalButton: {
    borderRadius: "4px",
  },
  actionButton: {
    borderRadius: "4px",
    backgroundColor: "#1890ff",
    borderColor: "#1890ff",
    color: "#FFFFFF",
  },
  dropdownMenu: {
    borderRadius: "4px",
  },
  tag: {
    cursor: "pointer",
  },
  favPill: {
    display: "flex",
    height: "20px",
    padding: "2px 6px",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    borderRadius: "24px",
    background: "var(--Color-Green-Green-50, #E7F5F1)",
    color: "var(--Color-Green-Green-500, #0C9F6E)",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
  },
  rejectPill: {
    display: "flex",
    height: "20px",
    padding: "2px 6px",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    borderRadius: "24px",
    background: "var(--Color-Red-Red-50, #FEEEEE)",
    color: "var(--Color-Red-Red-500, #F05152)",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
  },
  greyPill: {
    display: "flex",
    height: "20px",
    padding: "2px 8px 2px 4px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "24px",
    border: "1px solid var(--Neutral-300, #D1D5DB)",
    background: "var(--Neutral-100, #F3F4F6)",
  },
  viewMore: {
    color: "var(--Global-Mapping-Primary, #5A5FF2)",
    cursor: "pointer",
  },
};

const Consumers = ({ fetchConsumerTable, fetchConsumerTableResponse, fetchConsumerTeamsResponse, fetchConsumerTeams }) => {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [dataSource, setDataSource] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const [searchConsumer, setSearchConsumer] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [teamId, setTeamId] = useState("");
  const options = allTeams && allTeams.map((item) => ({
    value: item.team_id || item.agent_id,
    label: item.team_name || item.agent_name,
  }));

  const fetchConsumerTableData = () => {
    const { current, pageSize } = pagination;
    const payload = {
      limit: pageSize,
      skip: (current - 1) * pageSize,
      name: searchConsumer,
      location: searchLocation,
      teamId: teamId,
    };
    fetchConsumerTable(payload);
  };

  useEffect(() => {
    fetchConsumerTeams();
  }, []);

  useEffect(() => {
    fetchConsumerTableData();
  }, [fetchConsumerTable, pagination]);

  useEffect(() => {
    if (fetchConsumerTableResponse?.data) {
      setDataSource(fetchConsumerTableResponse.data.data);
    }
  }, [fetchConsumerTableResponse]);

  useEffect(() => {
    if (fetchConsumerTeamsResponse?.data) {
      setAllTeams(fetchConsumerTeamsResponse.data);
    }
  }, [fetchConsumerTeamsResponse]);

  const handlePageChange = (page, pageSize) => {
    setPagination({ current: page, pageSize });
    fetchConsumerTableData();
  };

  const debouncedConsumerSearch = useCallback(
    debounce((value) => {
      setSearchConsumer(value);
      const { current, pageSize } = pagination;
      const payload = {
        limit: pageSize,
        skip: (current - 1) * pageSize,
        name: value,
        location: searchLocation,
        teamId: teamId,
      };
      fetchConsumerTable(payload);
    }, 500),
    [fetchConsumerTable, searchLocation, teamId]
  );

  const handleConsumerSearch = (e) => {
    handlePageChange(1, 10);
    debouncedConsumerSearch(e.target.value);
  };

  const debouncedLocationSearch = useCallback(
    debounce((value) => {
      setSearchLocation(value);
      const { current, pageSize } = pagination;
      const payload = {
        limit: pageSize,
        skip: (current - 1) * pageSize,
        name: searchConsumer,
        location: value,
        teamId: "",
      };
      fetchConsumerTable(payload);
    }, 500),
    [fetchConsumerTable, searchConsumer, teamId]
  );

  const handleLocationSearch = (e) => {
    handlePageChange(1, 10);
    debouncedLocationSearch(e.target.value);
  };

  useEffect(() => {
    return () => {
      debouncedConsumerSearch.cancel();
      debouncedLocationSearch.cancel();
    };
  }, [debouncedConsumerSearch, debouncedConsumerSearch]);

  const handleOptionChange = (value) => {
    if(value === "all"){
      setTeamId("");
      const { current, pageSize } = pagination;
      const payload = {
        limit: pageSize,
        skip: (current - 1) * pageSize,
        name: searchConsumer,
        location: searchLocation,
        teamId: "",
      };
      fetchConsumerTable(payload);
    }
    else {
      setTeamId(value);
      const { current, pageSize } = pagination;
      const payload = {
        limit: pageSize,
        skip: (current - 1) * pageSize,
        name: searchConsumer,
        location: searchLocation,
        teamId: value,
      };
      fetchConsumerTable(payload);
    }
    
  };

  const searchBoardColumns = [
    {
      title: "App name/ Team name",
      dataIndex: "app_team_name",
      key: "app_team_name",
      width: 150,
      render: (status, record) => {
        return (
          <div>
            <p>{record.app_name}</p>
            <p style={{color: "grey"}}>{record.team_name}</p>
          </div>
        );
      },
    },
    {
      title: "Date downloaded",
      dataIndex: "downloaded_at",
      key: "downloaded_at",
      width: 150,
      render: (text, record) => {
        const formattedDate = moment(record.downloaded_at).format("MM/DD/YYYY");
        return (
          <>
            <div>{formattedDate}</div>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      render: (status, record) => {
        return (
          <div>
            {record.name ? (<p>{record.name}</p>) : (<p>-</p>)}
          </div>
        );
      },
    },
    {
      title: "Assigned agent",
      dataIndex: "team_agent",
      key: "team_agent",
      width: 150,
      render: (status, record) => {
        return (
          <div>
            {record.agent_name ? (
              <>
                <p>{record.agent_name}</p>
                <p style={{color: "grey"}}>{record.agent_email}</p>
              </>
            ) : (
              <p>-</p>
            )}
          </div>
        );
      },
    },
    {
      title: "Properties",
      dataIndex: "last_recommendation",
      key: "last_recommendation",
      width: 150,
      render: (status, record) => {
        const recommendationCount = record.properties_count;
        const fav = record.like_count;
        const reject = record.dislike_count;
        return (
          <div>
            <p>{recommendationCount} Recommended</p>
            <div style={{ display: "flex", gap: "5px" }}>
              <p style={styles.favPill}>{fav} favorite</p>
              <p style={styles.rejectPill}>{reject} rejected</p>
            </div>
            {recommendationCount > 0 && (
              <p
              onClick={() => handleViewMoreModal("consumer_propertyDetails", record.id)}
              style={styles.viewMore}
              >
                View details
              </p>
            )}
          </div>
        );
      },
    },
    {
      title: "Search criteria",
      dataIndex: "search_board_count",
      key: "search_board_count",
      width: 150,
      render: (status, record) => {
        const searches = record.search_board_count;
        return (
          <div>
            <p style={styles.greyPill}>{searches} Search criteria</p>
            {searches > 0 && (
              <p
                onClick={() =>
                  handleViewMoreModal("consumer_searchCriteriaDetails", record.id)
                }
                style={styles.viewMore}
              >
                View searches
              </p>
            )}
          </div>
        );
      },
    },
    {
      title: "Family members",
      dataIndex: "family_member_count",
      key: "family_member_count",
      width: 150,
      render: (status, record) => {
        const famMembers = record.family_member_count;
        return (
          <div>
            <p style={styles.greyPill}>{famMembers} members</p>
            {famMembers > 0 && (
              <p
                onClick={() =>
                  handleViewMoreModal("consumer_familyMemberDetails", record.id)
                }
                style={styles.viewMore}
              >
                View members
              </p>
            )}
          </div>
        );
      },
    },
  ];

  const [viewMoreLocations, setViewMoreLocations] = useState(false);
  const [columnType, setColumnType] = useState();
  const [recordId, setRecordId] = useState();

  const handleViewMoreModal = (columnType, id) => {
    setViewMoreLocations(true);
    setColumnType(columnType);
    setRecordId(id);
  };

  const handleCancel = () => {
    setViewMoreLocations(false);
    setColumnType(null);
  };

  return (
    <>
      {viewMoreLocations && (
        <ViewMoreModal
          isModalVisible={viewMoreLocations}
          columnType={columnType}
          handleCancel={handleCancel}
          record_consumerId={recordId}
        />
      )}
      <div style={styles.tableContainer}>
        <p>Total consumers: {fetchConsumerTableResponse?.data?.total_count || 0}</p>
        <div style={styles.filterWrapper}>
          <h1 style={styles.header}>Consumers</h1>
          <div
            style={{
              marginBottom: 16,
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Select
              defaultValue="all"
              style={styles.inputSearch}
              onChange={handleOptionChange}
              options={[
                { value: "all", label: "All" },
                ...options,
              ]}
            />
            <Input
              placeholder="Search consumer"
              onChange={(e) => handleConsumerSearch(e)}
              style={styles.inputSearch}
            />
            <Input
              placeholder="Search by location"
              onChange={(e) => handleLocationSearch(e)}
              style={styles.inputSearch}
            />
          </div>
        </div>
        <Table
          columns={searchBoardColumns}
          dataSource={dataSource}
          loading={fetchConsumerTableResponse?.isFetching}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: handlePageChange,
            total: fetchConsumerTableResponse?.data?.total_count || 0,
          }}
          scroll={{ x: 2000 }}
        />
      </div>
    </>
  );
};

export default ConsumerApp(Consumers);
