import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import US_STATES from "shared/US_STATES.json";
import { Button, Modal, Form, Input, Select, Upload, notification, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import aiContainer from "container/AI";
import root from "window-or-global";
import TechAssets from "container/TechAssets"
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const token = process.env.REACT_APP_SECRET_ID;
const { Option } = Select;

const FormModal = ({ toggleTrainingFormModal, trainingFormModalResponse, uploadLinkToFile, uploadPDFToS3WithNewCloudFunction }) => {
  const STATE_OPTIONS = Object.keys(US_STATES).map((key) => ({
    label: US_STATES[key],
    value: US_STATES[key],
  }));
  const [form] = Form.useForm();
  const [selectedFile, setSelectedfile] = useState(null);
  const [uploadType, setUploadType] = useState(null);
  const [uploadedFileUrl, setUploadedFileUrl] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 14,
      },
    },
  };

  const handleUploadType = (value) => {
    setUploadType(value)
    form.setFieldsValue({ file: null });
    form.setFieldsValue({ linkToFile: null });
  }

  const handleSubmit = async (values) => {
    const { category, description, documentName, file, linkToFile, state } = values || {}

    if (!linkToFile && !uploadedFileUrl) {
      notification.error({
        message: "Error",
        description:
          "Please either upload document or provide link to the file",
      })

      return
    }

    const data = {
      category: category || null,
      description: description || null,
      compliance_state: state || null,
    }

    if (uploadType === "uploadLinkToFile") {
      data.document_link = linkToFile || null
    } else if (uploadType === "uploadDocument") {
      data.document_link = uploadedFileUrl || null
    }

    const payload = {
      url_info: [data],
    }

    uploadLinkToFile({
      payload,
    })
  };

  const handleCancel = () => {
    form.resetFields();
    toggleTrainingFormModal(false);
  };
  const handleRemove = () => {
    setSelectedfile(null);
  };

  const normFile = (e) => {
    return e && e.fileList;
  };

  const handleUpload = (file) => {
    setSelectedfile(file);
  };

  const handleUploadFileToS3 = async (file) => {
    setUploadingFile(true)
    const location = "ai-training-docs"

    await uploadPDFToS3WithNewCloudFunction({
      file,
      objName: file.name,
      fileName: file.name,
      bucketName: process.env.NODE_ENV === "staging" || process.env.NODE_ENV === "development" ? `s-${location}` : location,
      callback: (url) => {
        if (url?.length > 0) {
          setUploadedFileUrl(url)
          setUploadingFile(false)
        }
      },
    })
  }

  return (
    <Modal
      title="Add Documents For Training"
      visible={trainingFormModalResponse}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={handleCancel}
      okText="Submit"
    >
      <Form
        {...formItemLayout}
        variant="filled"
        style={{
          maxWidth: 600,
        }}
        onFinish={(data) => {
          handleSubmit(data);
        }}
        form={form}
      >
        <Form.Item
          label="Description:"
          name="description"
          rules={[
            {
              required: true,
              message: "Description",
            },
          ]}
        >
          <Input style={{ marginLeft: 20 }} />
        </Form.Item>
        <Form.Item
          label="Select State"
          name="state"
          rules={[
            {
              required: false,
              message: "Please input!",
            },
          ]}
        >
          <Select style={{ marginLeft: 20 }}>
            {STATE_OPTIONS.map((st) => (
              <Select.Option key={st.value} value={st.value}>
                {st.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Category"
          name="category"
          rules={[
            {
              required: false,
              message: "Please input!",
            },
          ]}
        >
          <Input style={{ marginLeft: 20 }} />
        </Form.Item>
        <Form.Item
          label="Type of Upload"
          name="uploadType"
          rules={[
            {
              required: false,
              message: "Please Select!",
            },
          ]}
        >
          <Select 
            onChange={(value) => handleUploadType(value)}
            style={{ marginLeft: 20 }}>
            <Option value="uploadLinkToFile">Upload via Link</Option>
            <Option value="uploadDocument">Upload via Document</Option>
          </Select>
        </Form.Item>
        {uploadType == "uploadLinkToFile" && (
          <Form.Item
          label="Link To File"
          name="linkToFile"
          rules={[
            {
              //required: true,
              message: "Name",
            },
          ]}
        >
          <Input style={{ marginLeft: 20 }} />
        </Form.Item>
        )}
        {uploadType == "uploadDocument" && (
          <Form.Item
            label="Upload File"
            name="file"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              onRemove={handleRemove}
              showUploadList={{
                showRemoveIcon: true,
              }}
              beforeUpload={(file) => {
                handleUpload(file)
                handleUploadFileToS3(file)
                return false
              }}
            >
              <Button style={{ marginLeft: 20 }} icon={<UploadOutlined />}>
                Select File
              </Button>
            </Upload>
          </Form.Item>
        )}
        <Form.Item
          wrapperCol={{
            offset: 6,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit" style={{ marginLeft: 70 }} disabled={uploadingFile} loading={uploadingFile}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TechAssets(aiContainer(FormModal));
